import React, { useState, useEffect } from "react";

const WeatherWidget = () => {
  const [weatherData, setWeatherData] = useState(null);

  useEffect(() => {
    fetchWeatherData();
  }, []);

  const fetchWeatherData = async () => {
    try {
      const response = await fetch(
        "https://api.weatherapi.com/v1/forecast.json?key=7ba56254c8ab4dff953115551243010&q=Guwahati&days=3"
      );
      const data = await response.json();
      setWeatherData(data);
    } catch (error) {
      console.error("Error fetching weather data:", error);
    }
  };

  const formattedDateDisplay = (date) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  return (
    <div className=" lg:w-1/2 md:w-1/2 sm:w-full min-[320px]:w-full rounded-lg flex items-center">
      <div className="w-full">
        <div className="bg-white shadow rounded-lg p-5 dark:bg-gray-800 w-full">
          <h2 className="font-bold text-gray-800 text-lg dark:text-gray-400">
            {formattedDateDisplay(new Date())}
          </h2>

          {weatherData ? (
            <div>
              <div className="flex mt-4 mb-2">
                <div className="flex-1">
                  <div className="text-gray-600 text-sm dark:text-gray-400">
                    {weatherData.location.name}, {weatherData.location.region}
                  </div>
                  <div className="text-3xl font-bold text-gray-800 dark:text-gray-300">
                    {weatherData.current.temp_c} &deg;C
                  </div>
                  <div className="text-xs text-gray-600 dark:text-gray-400">
                    {weatherData.current.condition.text}
                  </div>
                </div>
                <div className="w-24">
                  <img
                    src={`https:${weatherData.current.condition.icon}`}
                    alt={weatherData.current.condition.text}
                    loading="lazy"
                  />
                </div>
              </div>

              <div className="flex space-x-2 justify-between border-t dark:border-gray-500">
                {weatherData.forecast.forecastday.slice(1).map((forecast, index) => (
                  <div
                    key={index}
                    className={`flex-1 text-center pt-3 ${
                      index === 0 ? "border-r dark:border-gray-500" : ""
                    }`}
                  >
                    <div className="text-xs text-gray-500 dark:text-gray-400">
                      {forecast.date.split("-").reverse().join("/")}
                    </div>
                    <img
                      src={`https:${forecast.day.condition.icon}`}
                      alt={forecast.day.condition.text}
                      loading="lazy"
                      className="mx-auto"
                    />
                    <div className="font-semibold text-gray-800 mt-1.5 dark:text-gray-300">
                      {forecast.day.maxtemp_c} &deg;C
                    </div>
                    <div className="text-xs text-gray-600 dark:text-gray-400">
                      {forecast.day.condition.text}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="animate-pulse">
              <div className="flex mt-4 mb-5">
                <div className="flex-1">
                  <div className="rounded h-2 mb-1.5 bg-gray-200 w-1/2"></div>
                  <div className="bg-gray-200 rounded h-4"></div>
                  <div className="rounded h-2 mt-1.5 bg-gray-200 w-1/2"></div>
                </div>
                <div className="w-24">
                  <div className="w-12 h-12 rounded-full bg-gray-100 mx-auto"></div>
                </div>
              </div>
              <div className="flex space-x-2 justify-between border-t h-32 dark:border-gray-500">
                {[...Array(2)].map((_, idx) => (
                  <div
                    key={idx}
                    className="flex-1 text-center pt-4 px-5 border-r dark:border-gray-500"
                  >
                    <div className="rounded h-2 mb-2 bg-gray-200 w-1/2 mx-auto"></div>
                    <div className="w-12 h-12 rounded-full bg-gray-100 mx-auto mb-2"></div>
                    <div className="rounded h-3 mt-1 bg-gray-200 mt-1.5 mx-auto"></div>
                    <div className="rounded h-2 mt-1 bg-gray-200 w-1/2 mx-auto"></div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

      </div>
    </div>
  );
};

export default WeatherWidget;
