import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useSelector } from "react-redux";
import CustomAxios from "../../../API/CustomAxios";
import Alert from "../../../Components/Alert";

function CreateProduct() {
    const storeAddress = useSelector((state) => state.auth.storeAddress);
    const userId = useSelector((state) => state.auth.userID);
    const brand = useSelector((state) => state.auth.brandname);
    const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });
    const API_BASE_SMARTAJAP = process.env.REACT_APP_API_SMARTAJAP;

    // Hide alert
    const hideAlert = () => {
        setAlertProps({ ...alertProps, show: false });
    };

    const validate = Yup.object({
        productID: Yup.string().required("*Required"),
        productName: Yup.string().required("*Required"),
        productType: Yup.string().required("*Required"),
        productDetails: Yup.array().of(
            Yup.object().shape({
                productDetailsID: Yup.string().required("*Required"),
                productVolume: Yup.string().required("*Required"),
                productPrice: Yup.number().required("*Required"),
                productStock: Yup.string().required("*Required"),
                productImage: Yup.string().url("Invalid URL").required("*Required"),
            })
        )
    });

    const handleProductCreation = async (values) => {
        const formattedData = {
            brandDetail: [
                {
                    supplierID: userId,
                    supplierAddress: storeAddress,
                    brandName: brand,
                    products: [
                        {
                            productID: values.productID,
                            productType: values.productType,
                            productName: values.productName,
                            productDetails: values.productDetails.map((detail) => ({
                                productDetailsID: detail.productDetailsID,
                                productVolume: detail.productVolume,
                                productPrice: parseFloat(detail.productPrice),
                                productStock: detail.productStock,
                                productImage: detail.productImage
                            }))
                        }
                    ]
                }
            ]
        };

        try {
            const response = await CustomAxios({
                method: "POST",
                baseURL: API_BASE_SMARTAJAP,
                url: "createProduct",
                data: formattedData,
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (response.status === 201) {
                setAlertProps({message: "Product created successfully!",severity: "success",show: true});
            }
        } catch (error) {
            setAlertProps({
                message: "Failed to create product.",
                severity: "error",
                show: true
            });
            console.error("Error creating product:", error);
        }
    };

    return (
        <div className="bg-white rounded-md shadow-lg flex flex-col p-4 mt-4">
            <Formik
                initialValues={{
                    productID: "",
                    productName: "",
                    productType: "",
                    productDetails: [
                        {
                            productDetailsID: "",
                            productVolume: "",
                            productPrice: "",
                            productStock: "",
                            productImage: ""
                        }
                    ]
                }}
                validationSchema={validate}
                onSubmit={handleProductCreation}
            >
                {formik => (
                    <Form>
                        {/* Product Info Fields */}
                        <div className="flex flex-row justify-between p-2 gap-12">
                            {/* Product ID */}
                            <div className="flex flex-col w-full">
                                <label className="text-xs">Product ID</label>
                                <Field
                                    type="text"
                                    name="productID"
                                    className="bg-gray-100 w-full mt-1 p-2 text-[12px] rounded-md focus:outline-none"
                                />
                                <ErrorMessage name="productID" component="div" className="text-red-500 text-[9px] italic mt-1" />
                            </div>

                            {/* Product Name */}
                            <div className="flex flex-col w-full">
                                <label className="text-xs">Product Name</label>
                                <Field
                                    type="text"
                                    name="productName"
                                    className="bg-gray-100 w-full mt-1 p-2 text-[12px] rounded-md focus:outline-none"
                                />
                                <ErrorMessage name="productName" component="div" className="text-red-500 text-[9px] italic mt-1" />
                            </div>

                            {/* Product Type */}
                            <div className="flex flex-col w-full">
                                <label className="text-xs">Product Type</label>
                                <Field
                                    as="select"
                                    name="productType"
                                    className="border w-full p-2 mt-1 text-[12px] rounded-lg focus:outline-none"
                                >
                                    <option value="">Select Type</option>
                                    <option value="fertilizer">Fertilizer</option>
                                    <option value="pesticide">Pesticide</option>
                                    <option value="seeds">Seeds</option>
                                </Field>
                                <ErrorMessage name="productType" component="div" className="text-red-500 text-[9px] italic mt-1" />
                            </div>
                        </div>

                        {/* Product Details Array */}
                        <FieldArray name="productDetails">
                            {({ push, remove, form }) => (
                                <>
                                    {form.values.productDetails.map((item, index) => (
                                        <div key={index} className="flex flex-row gap-4 mt-4 border p-3 rounded-md">
                                            {/* Product Image URL */}
                                            <div className="w-1/4 flex flex-col">
                                                <label className="text-sm mb-1">Product Image URL</label>
                                                <Field
                                                    type="url"
                                                    name={`productDetails[${index}].productImage`}
                                                    placeholder="Enter image URL"
                                                    className="border w-full p-3 text-[12px] rounded-lg"
                                                />
                                                {form.values.productDetails[index].productImage && (
                                                    <img
                                                        src={form.values.productDetails[index].productImage}
                                                        alt="Preview"
                                                        className="mt-2 w-24 h-24 object-cover"
                                                    />
                                                )}
                                                <ErrorMessage name={`productDetails[${index}].productImage`} component="div" className="text-red-500 text-[9px] italic mt-1" />
                                            </div>

                                            {/* Other Product Detail Fields */}
                                            <div className="w-3/4 grid grid-cols-2 gap-3">
                                                <div className="flex flex-col">
                                                    <label className="text-xs">Item ID</label>
                                                    <Field
                                                        type="text"
                                                        name={`productDetails[${index}].productDetailsID`}
                                                        className="bg-gray-100 w-full p-3 mt-2 text-[12px] rounded-md"
                                                    />
                                                    <ErrorMessage name={`productDetails[${index}].productDetailsID`} component="div" className="text-red-500 text-[9px] italic mt-1" />
                                                </div>

                                                <div className="flex flex-col">
                                                    <label className="text-xs">Product Volume</label>
                                                    <Field
                                                        type="text"
                                                        name={`productDetails[${index}].productVolume`}
                                                        className="bg-gray-100 w-full p-3 mt-2 text-[12px] rounded-md"
                                                    />
                                                    <ErrorMessage name={`productDetails[${index}].productVolume`} component="div" className="text-red-500 text-[9px] italic mt-1" />
                                                </div>

                                                <div className="flex flex-col">
                                                    <label className="text-xs">Product Price</label>
                                                    <Field
                                                        type="text"
                                                        name={`productDetails[${index}].productPrice`}
                                                        className="bg-gray-100 w-full p-3 mt-2 text-[12px] rounded-md"
                                                    />
                                                    <ErrorMessage name={`productDetails[${index}].productPrice`} component="div" className="text-red-500 text-[9px] italic mt-1" />
                                                </div>

                                                <div className="flex flex-col">
                                                    <label className="text-xs">Product Stock</label>
                                                    <Field
                                                        type="text"
                                                        name={`productDetails[${index}].productStock`}
                                                        className="bg-gray-100 w-full p-3 mt-2 text-[12px] rounded-md"
                                                    />
                                                    <ErrorMessage name={`productDetails[${index}].productStock`} component="div" className="text-red-500 text-[9px] italic mt-1" />
                                                </div>
                                            </div>

                                            {/* Remove Button */}
                                            <button type="button" onClick={() => remove(index)} className="text-red-500 text-sm font-bold ml-2">
                                                Remove
                                            </button>
                                        </div>
                                    ))}

                                    {/* Add More Items Button */}
                                    <div className="flex items-end justify-end">
                                        <button
                                            type="button"
                                            onClick={() => push({ productDetailsID: "", productVolume: "", productPrice: "", productStock: "", productImage: "" })}
                                            className="mt-4 bg-agro_darkgreen text-sm text-white p-2 rounded-md"
                                        >
                                            Add More Items
                                        </button>
                                    </div>
                                </>
                            )}
                        </FieldArray>

                        {/* Submit Button */}
                        <div className="flex justify-center mt-8">
                            <button type="submit" className="bg-agro_green text-sm text-white px-8 py-2 rounded-md">
                                Submit
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>

            {alertProps.show && (
                <Alert message={alertProps.message} severity={alertProps.severity} onClose={hideAlert} />
            )}
        </div>
    );
}

export default CreateProduct;
