import React, { useState } from 'react'
import DatePicker from './DatePicker'
import HarvestInfo from './HarverstInfo'
import LineChart from './LineChart'
import LandForm from './LandForm'
import LandDeatils from './LandDetails'

function Cultivation() {
  const [landData, setLandData] = useState(null);

  const handleLandDataSubmit = (data) => {
    setLandData(data);
  };

  const handleLandDataUpdate = (updatedData) => {
    setLandData(updatedData);
  };
  return (
    <div className='flex flex-col'>
    
      <div className="py-2 flex lg:flex-row md:flex-row sm:flex-col min-[320px]:flex-col gap-x-2 gap-y-2">
        <LandForm onSubmit={handleLandDataSubmit} />
        <HarvestInfo></HarvestInfo>
      </div>

      
      <div className='py-2 flex lg:flex-row md:flex-row sm:flex-col min-[320px]:flex-col gap-x-2 gap-y-2'>
        {/* <div className='lg:w-1/2 md:w-1/2 sm:w-full min-[320px]:w-full'>
        </div> */}
        <div className='w-full'>
          <LineChart className="w-full"></LineChart>
        </div>

      </div>




    </div>
  )
}

export default Cultivation