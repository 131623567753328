// PaymentCard.jsx
import React, { useState } from "react";

const PaymentCard = () => {
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");

  const handleCardNumberChange = (e) => {
    setCardNumber(e.target.value);
  };

  const handleExpiryDateChange = (e) => {
    setExpiryDate(e.target.value);
  };

  const handleCvvChange = (e) => {
    setCvv(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form Submitted", { cardNumber, expiryDate, cvv });
  };

  return (
    <div className="max-w-lg mx-auto my-10 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-center mb-6 text-agro_green">Payment Information</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="card-number" className="block text-sm font-medium text-gray-700">
            Card Number
          </label>
          <input
            type="text"
            id="card-number"
            value={cardNumber}
            onChange={handleCardNumberChange}
            maxLength="19"
            placeholder="1234 5678 9012 3456"
            className="w-full mt-1 p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="flex space-x-4">
          <div className="mb-4 flex-1">
            <label htmlFor="expiry-date" className="block text-sm font-medium text-gray-700">
              Expiry Date
            </label>
            <input
              type="text"
              id="expiry-date"
              value={expiryDate}
              onChange={handleExpiryDateChange}
              maxLength="5"
              placeholder="MM/YY"
              className="w-full mt-1 p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="mb-4 flex-1">
            <label htmlFor="cvv" className="block text-sm font-medium text-gray-700">
              CVV
            </label>
            <input
              type="text"
              id="cvv"
              value={cvv}
              onChange={handleCvvChange}
              maxLength="3"
              placeholder="123"
              className="w-full mt-1 p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        <button
          type="submit"
          className="w-full py-3 mt-6 bg-agro_green/95 text-white font-semibold rounded-md shadow-md hover:bg-agro_green/80 focus:ring-2 focus:ring-agro_green"
        >
          Pay Now
        </button>
      </form>
    </div>
  );
};

export default PaymentCard;
