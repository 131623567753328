import React from 'react'
import WeatherWidget from './WeatherWidget'
import MapCard from './MapcardComponent'
import ReminderCard from './ReminderCard';
import BarChart from './BarChart'

function FarmerDashboard() {
  const center = [51.505, -0.09];
  const zoom = 13;

  return (
    <div className='flex flex-col gap-y-4 py-5'>
        <div className='flex lg:flex-row gap-x-2 md:flex-row sm:flex-col min-[320px]:flex-col'>
            <WeatherWidget></WeatherWidget>
            <MapCard center={center} zoom={zoom}></MapCard>
        </div>
        <div className='flex lg:flex-row gap-x-2 md:flex-row sm:flex-col min-[320px]:flex-col'>
            <ReminderCard></ReminderCard>
            <BarChart></BarChart>       
        </div>
    </div>
  )
}

export default FarmerDashboard
