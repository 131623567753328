import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import SignupKey from './SignupKey';
import { showSignupKey, hideSignupKey, setSignupUsername } from '../ReduxManagement/signupSlice';
import CustomAxios from '../../API/CustomAxios';
import Alert from '../../Components/Alert';
import * as Yup from 'yup';


function Signup() {
  const API_BASE = process.env.REACT_APP_API_BASE;
  const API_BASE_SMARTSCAP = process.env.REACT_APP_API_SMARTSCAP;
  const API_BASE_SMARTAJAP = process.env.REACT_APP_API_SMARTAJAP;
  const API_BASE_NORMALSCAP = process.env.REACT_APP_API_NORMALSCAP;
  const API_BASE_NORMALAJAP = process.env.REACT_APP_API_NORMALAJAP;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showKeyModel = useSelector((state) => state.signup.showSignupKey);
  const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });


  //hide alerts
  const hideAlert = () => {
    setAlertProps({ ...alertProps, show: false });
  };
  
  
  //handling the sign up
  const handleRegister = async(values)=>{
      const {confirm_password, ...newValues} = values;
      const signupValues = {user:newValues};
      console.log(signupValues)

      try{
        const signupResponse = await CustomAxios({
          method:"POST",
          baseURL:API_BASE_NORMALSCAP,
          url:"user/signup",
          data: signupValues,
          headers:{
            "Content-Type":"application/json"
          },
        })
        if(signupResponse.status === 200){
          // console.log(signupResponse);
          // console.log(signupResponse.data.user.username);
          setAlertProps({severity: "error",message: signupResponse.data.message,show: true,}); 
          dispatch(showSignupKey());
          dispatch(setSignupUsername(signupResponse.data.user.username))
        }else{
          setAlertProps({severity: "error",message: signupResponse.response.data.error,show: true,});
        }
      }catch(error){
        const errorMessage = error.response?.data?.error || "An unexpected error occurred.";
        setAlertProps({ severity: "error", message: errorMessage, show: true });
      }
  }
   
    // validation for signup
    const validate = Yup.object({
        username:Yup.string()
        .min(8, "Must be more than 8 characters")
        .max(100, "Must be less than 100 characters")
        .matches( /^[a-z0-9@_]+$/,"*Username must include at least one number and can only contain lowercase letters, @, and _")
        .required("*Required"),
        email: Yup.string()
        .email("Invalid email")
        .required("*Required"),
        name: Yup.string()
        .min(3, "Must be more than 3 characters")
        .max(100, "Must be 100 characters or less")
        .required("*Required"),
        address: Yup.string()
        .required("*Required"),
        // nic: Yup.string()
        // .required("*Required"),
        contactno: Yup.string()
        .required("*Required"),
        password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .max(100, "Must be less than 100 characters")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(/[@_]/, "Password must contain at least one symbol (@ or _)")
        .required("*Password is Required"),
        confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "*Passwords must match")
        .required("*Confirm password is required"),
    });
  

    return (
     <>
      <Formik
        initialValues={{
          username:"",
          name:'',
          address:"",
          // nic:"",
          contactno:"",
          password:"",
          confirm_password:'',
          email:'',
          role:"farmer",
          status:"activate",
          isVerified:false,
        }}
        validationSchema={validate}
        onSubmit={handleRegister}
      >
       {(formik)=>(
        <Form className='flex flex-col gap-2 w-full mt-8 h-full mob2:px-12 md:px-0'>
   
          {/* Field for username */}
          <div className='flex flex-col h-12'>
            <Field
              type="text"
              id="username"
              name="username"
              placeholder="UserName"
              onChange={formik.handleChange}
              value={formik.values.username}
              className="border w-full focus:outline-none p-3 text-[12px] text-gray-500 rounded-lg"
            />
            <ErrorMessage 
              name="username" 
              component="div" 
              className="text-red-500 text-[9px] font-medium italic " 
            />      
          </div>


          {/* Field for name */}
          <div className='flex flex-col h-12'>
            <Field
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              onChange={formik.handleChange}
              value={formik.values.name}
              className="border w-full focus:outline-none p-3 text-[12px] text-gray-500 rounded-lg"
            />
            <ErrorMessage 
              name="name" 
              component="div" 
              className="text-red-500 text-[9px] font-medium italic " 
            />      
          </div>
  

          {/* Field for address */}
           <div className='flex flex-col h-12'>
            <Field
              type="text"
              id="address"
              name="address"
              placeholder="Address"
              onChange={formik.handleChange}
              value={formik.values.address}
              className="border w-full focus:outline-none p-3 text-[12px] text-gray-500 rounded-lg"
            />
            <ErrorMessage 
              name="address" 
              component="div" 
              className="text-red-500 text-[9px] font-medium italic" 
            />      
          </div>


          {/* Field for NIC */}
          {/* <div className='flex flex-col h-12'>
            <Field
              type="text"
              id="nic"
              name="nic"
              placeholder="UID"
              onChange={formik.handleChange}
              value={formik.values.nic}
              className="border w-full focus:outline-none p-3 text-[12px] text-gray-500 rounded-lg"
            />
            <ErrorMessage 
              name="nic" 
              component="div" 
              className="text-red-500 text-[9px] font-medium italic" 
            />      
          </div> */}
  

           {/* Field for contact number */}
           <div className='flex flex-col h-12'>
            <Field
              type="text"
              id="contactno"
              name="contactno"
              placeholder="Contact No."
              onChange={formik.handleChange}
              value={formik.values.contactno}
              className="border w-full focus:outline-none p-3 text-[12px] text-gray-500 rounded-lg"
            />
            <ErrorMessage 
              name="contactno" 
              component="div" 
              className="text-red-500 text-[9px] font-medium italic" 
            />      
          </div>

  
          {/* Field for Email*/}
          <div className='flex flex-col h-12'>
            <Field
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              onChange={formik.handleChange}
              value={formik.values.email}
              className="border w-full focus:outline-none p-3 text-[12px] text-gray-500 rounded-lg"
            />
            <ErrorMessage 
              name="email" 
              component="div" 
              className="text-red-500 text-[9px] font-medium italic" 
            />      
          </div>
  
          {/* Field for password */}
          <div className='flex flex-col h-12'>
            <Field
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              onChange={formik.handleChange}
              value={formik.values.password}
              className="border w-full focus:outline-none p-3 text-[12px] text-gray-500 rounded-lg"
            />
            <ErrorMessage 
              name="password" 
              component="div" 
              className="text-red-500 text-[9px] font-medium italic" 
            />      
          </div>
  
  
        {/* Field for Confirm password */}
          <div className='flex flex-col h-12'>
            <Field
              type="password"
              id="confirm_password"
              name="confirm_password"
              placeholder="Confirm Password"
              onChange={formik.handleChange}
              value={formik.values.confirm_password}
              className="border w-full focus:outline-none p-3 text-[12px] text-gray-500 rounded-lg"
            />
            <ErrorMessage 
              name="confirm_password" 
              component="div" 
              className="text-red-500 text-[9px] font-medium italic" 
            />      
          </div>
  
        <div className='flex flex-col items-center gap-4 mt-4'>
            <button
              type="submit"
              className='bg-agro_darkgreen font-semibold w-full text-[13px] text-white p-2  rounded-lg'
            >
              SignUp
            </button>
            <h3 className='font-light text-black/50 text-[12px]  '>Already have an account?
              <button 
                onClick={()=>navigate("/")}
                className='text-agro_darkgreen font-bold ml-1 text-[13px]'
              > 
                Login to the Account
              </button> 
            </h3>
        </div>
        </Form>
       )}
      </Formik>  
      
      {/* Calling the Alert component */}
        <Alert
          message={alertProps.message}
          severity={alertProps.severity}
          show={alertProps.show}
          onClose={hideAlert}
       />

    </>
    );
  }
  

export default Signup