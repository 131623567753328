import React, { useEffect, useState } from 'react';
import TablePagination from '@mui/material/TablePagination';
import edit from '../../Assests/edit.png';
import deleteimg from '../../Assests/delete.png';
import correct from "../../Assests/correct.png";
import CustomAxios from '../../API/CustomAxios';
import { useSelector } from 'react-redux';


function SuppliersUserList() {
    const API_BASE_SMARTSCAP = process.env.REACT_APP_API_SMARTSCAP;
    const API_BASE_SMARTAJAP = process.env.REACT_APP_API_SMARTAJAP;
    const API_BASE_NORMALSCAP = process.env.REACT_APP_API_NORMALSCAP;
    const API_BASE_NORMALAJAP = process.env.REACT_APP_API_NORMALAJAP;
    const refreshToken = useSelector((state) => state.auth.REFRESHTOKEN);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editedStatus, setEditedStatus] = useState(null); 
    const [selectedUser, setSelectedUser] = useState("");
    const [displayList, setDisplayList] = useState([]);
    const [showdDeleteModel, setshowdDeleteModel] = useState(false);


    //call the useeffect to get the supplier user list
    useEffect(() => {
        getSupplierList();
    }, []);


    //fetch the supplier list
    const getSupplierList = async()=>{
        try{
            const response = await CustomAxios({
                method:"GET",
                baseURL:API_BASE_NORMALSCAP,
                url:"allsuppliers",
                headers:{
                  "Content-Type":"application/json",
                  "token": refreshToken,
                },
            })
            if(response.status === 200){
                console.log(response.data);
                setDisplayList(response.data.supplier|| []);
            }
        }catch(error){
            console.log(error)
        }
    }


    // Handle status change
    const handleStatusChange = (index, newStatus) => {
        setEditedStatus(newStatus); 
    };


    // Handle supplier update by calling the endpoint
    const handleSupplierUpdate = async () => {
        try {
            const response = await CustomAxios({
                method:"PUT",
                baseURL:API_BASE_SMARTAJAP,
                url:"api2/v2/suppliers",
                data: editedStatus,
                headers:{
                  "Content-Type":"application/json"
                },
            })
            if(response.status === 200){
                const updatedList = displayList.map((user, id) => id === editingIndex ? {...user, status:editedStatus} : user);
                setDisplayList(updatedList);
                setEditingIndex(null);
            }
        } catch (error) {
            console.error("Error updating supplier:", error);
        }
        setEditingIndex(null);
    };

    // Show the delete model
    const handleShowDeleteModel = (Supplieruser) => {
        setshowdDeleteModel(true);
        setSelectedUser(Supplieruser);
    };


    //handle the supplier delete
    const handledeleteSupplier = async()=>{
        const updatedList = displayList.filter(user => user.username !== selectedUser.username);
        setDisplayList(updatedList);
        setshowdDeleteModel(false);
        try{
            const response = await CustomAxios({
                method:"DELETE",
                baseURL:API_BASE_SMARTAJAP,
                url:"api2/v2/suppliers",
                data: selectedUser,
                headers:{
                  "Content-Type":"application/json"
                },
            })

            if(response.status === 200){
                const updatedList = displayList.filter((user => user.username !== selectedUser.username));
                setDisplayList(updatedList);
                setshowdDeleteModel(false);
            }
        }catch(error){
            console.log(error);
        }
    }
    

    //handle the page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    //handle the rows per page
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    return (
    <>
        <div className='flex flex-col rounded-lg mt-4'>
            <div className='flex flex-col'>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[2, 10, 25, 50]}
                    page={page}
                    count={displayList.length}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <div className='overflow-x-auto rounded-lg bg-white'>
                    <table className='min-w-full rounded-lg'>
                        <thead className='bg-agro_lightgreen/60 text-black/70 text-left'>
                            <tr>
                                <th className='px-6 py-3 text-xs'>No</th>
                                <th className='px-6 py-3 text-xs'>Name</th>
                                <th className='px-6 py-3 text-xs'>Username</th>
                                <th className='px-6 py-3 text-xs'>Brand Name</th>
                                <th className='px-6 py-3 text-xs'>Email</th>
                                <th className='px-6 py-3 text-xs'>Status</th>
                                <th className='px-6 py-3 text-xs'>Verification</th>
                                <th className='px-6 py-3 text-xs'>Phone No</th>
                                <th className='px-6 py-3 text-xs'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) =>
                                <tr key={index} className='text-xs font-normal border border-b-agro_lightgreen/50'>
                                    <td className='px-6 py-3'>{page * rowsPerPage + index + 1}</td>
                                    <td className='px-6 py-3'>{user.name}</td>
                                    <td className='px-6 py-3'>{user.username}</td>
                                    <td className='px-6 py-3'>{user.brandName}</td>
                                    <td className='px-6 py-3'>{user.email}</td>
                                    <td className='px-6 py-3'>
                                        <select
                                            value={editingIndex === index ? editedStatus : user.status}
                                            disabled={editingIndex !== index}
                                            onChange={(e) => handleStatusChange(index, e.target.value)}
                                            className={`${editingIndex === index ? "bg-agro_lightgreen/60 " : ""} p-1 rounded-md`}
                                        >
                                            <option value="Active">Active</option>
                                            <option value="Deactive">Deactive</option>
                                        </select>
                                    </td>
                                    <td className='px-6 py-3'>{user.isVerified ? "verified" : "Not Verified"}</td>
                                    <td className='px-6 py-3'>{user.contactNo}</td>
                                    <td className='px-6 py-3 flex flex-row'>
                                        <button 
                                            className={`${editingIndex === index ? "hidden" : "block"}`} 
                                            onClick={() => {setEditingIndex(index); setEditedStatus(user.status);}}
                                        >
                                            <img src={edit} className='w-5 h-5' alt="Edit" />
                                        </button>
                                        {editingIndex === index &&
                                            <button onClick={handleSupplierUpdate}>
                                                <img src={correct} className="w-5 h-5" alt="Confirm" />
                                            </button>
                                        }
                                        <button onClick={()=>handleShowDeleteModel(user)}>
                                            <img src={deleteimg} className='w-5 h-5' alt="Delete" />
                                        </button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

     {/* show the delete model */}
     {showdDeleteModel && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-96 flex flex-col items-center justify-center gap-4">
                <h1 className='font-semibold text-agro_darkgreen text-lg'>Confirm Delete</h1>
                <h1 className='text-sm mb-4'>Are you sure you want to delete <span className='font-semibold text-md'>{selectedUser.name}</span>?</h1>
                <div className='flex flex-row w-full justify-between gap-2 text-white text-sm font-semibold'>
                    <button
                        className='bg-agro_greenlight py-2 w-full rounded-md px-4'
                        onClick={handledeleteSupplier}
                    >
                        Delete
                    </button>
                    <button
                        className='bg-red-500 w-full py-2 rounded-md px-4'
                        onClick={() => setshowdDeleteModel(false)}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )}
</>       
    );
}

export default SuppliersUserList;
