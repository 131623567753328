import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CultivationForm = () => {
  const [seedType, setSeedType] = useState('');
  const [cultivationStartDate, setCultivationStartDate] = useState(null);
  const [seasonType, setSeasonType] = useState('');
  const [landSize, setLandSize] = useState('');
  const [cultivationId, setCultivationId] = useState(null); 
  const farmerID = useSelector((state) => state.signup.username);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEditing, setIsEditing] = useState(false); 
  const [submittedData, setSubmittedData] = useState(null);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!cultivationStartDate || !seedType || !seasonType || !landSize) {
      alert("Please fill in all the fields.");
      return;
    }

    const dateToSend = cultivationStartDate.toISOString().split('T')[0]; 
    const data = {
      farmerid: farmerID,  
      seed_type: seedType,
      start_date: dateToSend,
      season: seasonType,
      cultivated_landsize: parseFloat(landSize), 
    };

    try {
      const response = await fetch('https://scapione.bethel.network/createCultivation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      if (result.CultivationID) {
        setCultivationId(result.CultivationID);
        setSubmittedData({
          ...data,
          cultivationId: result.CultivationID,
        });
        setIsSubmitted(true);
        setIsEditing(false); 
        console.log('Data after submission:', result);  // Log to check submitted data
      } else {
        throw new Error('CultivationID missing in the response');
      }
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  // Handle the update
  const handleUpdate = async () => {
    if (!cultivationId) {
      alert("Cultivation ID is missing. Please submit the form first.");
      return;
    }

    const dataToUpdate = {
      FarmerID: farmerID, 
      CultivationID: cultivationId,  
      SeedType: seedType,
      StartDate: cultivationStartDate.toISOString().split('T')[0], 
      Season: seasonType,
      CultivatedLandSize: parseFloat(landSize),  
    };

    console.log("Updating data:", dataToUpdate); // Log to check the data being updated

    try {
      const response = await fetch('https://scapione.bethel.network/updateCultivation', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToUpdate),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      alert("Cultivation updated successfully.");
      setIsEditing(false); 

      // Update the state after successful update
      setSubmittedData((prevData) => ({
        ...prevData, 
        seed_type: dataToUpdate.SeedType,
        start_date: dataToUpdate.StartDate,
        season: dataToUpdate.Season,
        cultivated_landsize: dataToUpdate.CultivatedLandSize,
      }));

      console.log("Updated data:", submittedData);  // Log to check updated data

    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  // Handle Edit button click
  const handleEdit = () => {
    setIsEditing(true); // Enable edit mode
    setSeedType(submittedData.seed_type);
    setCultivationStartDate(new Date(submittedData.start_date));
    setSeasonType(submittedData.season);
    setLandSize(submittedData.cultivated_landsize);
  };

  // Format the date for display (e.g., MM/DD/YYYY)
  const formatDate = (date) => {
    if (!date) return '';
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 lg:w-1/2 md:w-1/2 sm:w-full min-[320px]:w-full">
      <h2 className="text-2xl font-bold mb-4 text-center">Cultivation Information</h2>

      {!isSubmitted ? (
        // Form to fill out when not submitted
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Seed Type:</label>
            <select
              value={seedType}
              onChange={(e) => setSeedType(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-full"
              required
            >
              <option value="" disabled>Select Seed Type</option>
              <option value="Seed 1">Seed 1</option>
              <option value="Seed 2">Seed 2</option>
              <option value="Seed 3">Seed 3</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Cultivation Start Date:</label>
            <DatePicker
              selected={cultivationStartDate}
              onChange={(date) => setCultivationStartDate(date)}
              className="border border-gray-300 rounded-md p-2 w-full"
              placeholderText="Select a date"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Season Type:</label>
            <select
              value={seasonType}
              onChange={(e) => setSeasonType(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-full"
              required
            >
              <option value="" disabled>Select Season Type</option>
              <option value="Yala">Yala</option>
              <option value="Maha">Maha</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Cultivated Land Size (acres):</label>
            <input
              type="number"
              value={landSize}
              onChange={(e) => setLandSize(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-full"
              placeholder="Enter land size in acres"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full bg-agro_green/70 text-white font-bold py-2 rounded-md hover:bg-agro_green"
          >
            Submit
          </button>
        </form>
      ) : isEditing ? (
        // Form to edit the submitted data
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Seed Type:</label>
            <select
              value={seedType}
              onChange={(e) => setSeedType(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-full"
              required
            >
              <option value="Seed 1">Seed 1</option>
              <option value="Seed 2">Seed 2</option>
              <option value="Seed 3">Seed 3</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Cultivation Start Date:</label>
            <DatePicker
              selected={cultivationStartDate}
              onChange={(date) => setCultivationStartDate(date)}
              className="border border-gray-300 rounded-md p-2 w-full"
              placeholderText="Select a date"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Season Type:</label>
            <select
              value={seasonType}
              onChange={(e) => setSeasonType(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-full"
              required
            >
              <option value="Yala">Yala</option>
              <option value="Maha">Maha</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Cultivated Land Size (acres):</label>
            <input
              type="number"
              value={landSize}
              onChange={(e) => setLandSize(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-full"
              placeholder="Enter land size in acres"
              required
            />
          </div>

          <button
            type="button"
            onClick={handleUpdate}
            className="w-full bg-agro_green/70 text-white font-bold py-2 rounded-md hover:bg-agro_green"
          >
            Update
          </button>
        </form>
      ) : (
        // Displaying the submitted data when the form is submitted
        <div className='gap-y-6 flex flex-col py-[30px]'>
          <p><strong>Seed Type:</strong> {submittedData.seed_type}</p>
          <p><strong>Cultivation Start Date:</strong> {formatDate(submittedData.start_date)}</p>
          <p><strong>Season Type:</strong> {submittedData.season}</p>
          <p><strong>Cultivated Land Size (acres):</strong> {submittedData.cultivated_landsize}</p>

          <button
            onClick={handleEdit}
            className="w-full mt-4 bg-agro_green/70 text-white font-bold py-2 rounded-md hover:bg-agro_green"
          >
            Edit
          </button>
        </div>
      )}
    </div>
  );
};

export default CultivationForm;
