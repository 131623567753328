// ReminderCard.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faTrash } from '@fortawesome/free-solid-svg-icons';

const ReminderCard = () => {
  const [reminders, setReminders] = React.useState([
    'Fertilizer Time 8 AM',
    'Pesticide Time 4 PM',
    'Harrvest Time in Next Week',
  ]);

  const handleDeleteReminder = (index) => {
    const updatedReminders = reminders.filter((_, i) => i !== index);
    setReminders(updatedReminders);
  };

  return (
    <div className="rounded-lg overflow-hidden shadow-lg bg-white p-6 lg:w-1/2 md:w-1/2 sm:w-full min-[320px]:w-full">
      <h2 className="text-xl font-bold mb-4">Reminders</h2>
      <ul>
        {reminders.map((reminder, index) => (
          <li key={index} className="flex justify-between items-center mb-4 p-2 border-b border-gray-200">
            <div className="flex items-center">
              <FontAwesomeIcon icon={faBell} className="text-yellow-500 mr-2" />
              <span>{reminder}</span>
            </div>
            <button
              onClick={() => handleDeleteReminder(index)}
              className="text-red-500 hover:text-red-700"
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ReminderCard;
