import React, { useState } from 'react';
import CustomAxios from '../../../../API/CustomAxios';
import edit from '../../../../Assests/newEdit.png';

function ItemsOrderedPerOder({ orderItems, orderID, onStatusChange }) {
  const API_BASE_SMARTAJAP = process.env.REACT_APP_API_SMARTAJAP;
  const API_BASE_SMARTSCAP = process.env.REACT_APP_API_SMARTSCAP;
  const [orderdItems, setOrderdItems] = useState(orderItems.itemDetails);
  const [editingIndex, setEditingIndex] = useState(null); // Track the index of the row being edited
  const [selectedStatus, setSelectedStatus] = useState({}); // Track the selected status per item

  console.log("nnn",orderItems)

  // Handling the update of the ordered items
  const handleOrderEdit = async (item) => {
    try {
      const orderupdatedResponse = await CustomAxios({
        method: "PUT",
        baseURL: API_BASE_SMARTSCAP,
        url: "updateOrderStatusBySupplier",
        params: {
           orderID: orderID,
           orderItemID: orderItems.orderItemId,
           itemDetailID: item.itemDetailId,
        },
        data: {
          ItemStatus: selectedStatus[item.itemDetailId] || item.ItemStatus,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (orderupdatedResponse.status === 200) {
        console.log("Item updated:", orderupdatedResponse.data);
        onStatusChange();
        // Update the status in the state after saving
        setOrderdItems((prevItems) =>
          prevItems.map((prevItem) =>
            prevItem.itemDetailId === item.itemDetailId
              ? { ...prevItem, ItemStatus: selectedStatus[item.itemDetailId] }
              : prevItem
          )
        );
        setEditingIndex(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle the edit icon click
  const handleEdit = (index) => {
    setEditingIndex(editingIndex === index ? null : index);
  };

  // Handle dropdown selection change
  const handleStatusChange = (itemId, status) => {
    setSelectedStatus((prevStatus) => ({ ...prevStatus, [itemId]: status }));
  };

  return (
    <div className='bg-white shadow-md rounded-md p-4 flex flex-col'>
      <h1>ITEMS ORDERED</h1>

      <div className='flex flex-col rounded-lg mt-6'>
        <div className='flex flex-col'>
          <div className='overflow-x-auto rounded-lg'>
            <table className='min-w-full rounded-lg'>
              <thead className='bg-agro_lightgreen text-black/50 text-left'>
                <tr>
                  <th className='px-6 py-3 text-xs'>No</th>
                  <th className='px-6 py-3 text-xs'>ItemID</th>
                  <th className='px-6 py-3 text-xs'>Item Name</th>
                  <th className='px-6 py-3 text-xs'>Quantity</th>
                  <th className='px-6 py-3 text-xs'>Item Status</th>
                  <th className='px-6 py-3 text-xs'>Unit Price</th>
                  <th className='px-6 py-3 text-xs'>Action</th>
                </tr>
              </thead>

              <tbody>
                {orderdItems.map((item, index) => (
                  <tr key={item.itemDetailId} className='text-xs font-normal border-b border-agro_lightgreen'>
                    <td className='px-6 py-3'>{index + 1}</td>
                    <td className='px-6 py-3'>{item.itemDetailId}</td>
                    <td className='px-6 py-3'>{item.item_name}</td>
                    <td className='px-6 py-3'>{item.quantity}</td>
                    <td className='px-6 py-3'>
                      <select
                        className={`bg-agro_lightgreen rounded-md px-2 p-1 ${editingIndex === index ? "" : "cursor-not-allowed opacity-50"}`}
                        disabled={editingIndex !== index}
                        value={selectedStatus[item.itemDetailId] || item.ItemStatus}
                        onChange={(e) => handleStatusChange(item.itemDetailId, e.target.value)}
                      >
                        <option value="Pending">Pending</option>
                        <option value="Accepted">Accepted</option>
                      </select>
                    </td>
                    <td className='px-6 py-3'>{item.unitPrice}</td>
                    <td className='px-6 py-3 w-full flex flex-row gap-2'>
                      <button onClick={() => handleEdit(index)}>
                        <img src={edit} className='w-3 h-3' alt="Edit" />
                      </button>
                      {editingIndex === index && (
                        <button className='text-[12px] font-semibold' onClick={() => handleOrderEdit(item)}>Save</button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemsOrderedPerOder;
