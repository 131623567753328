import React, { useState, useEffect } from 'react';
import { FaCamera } from 'react-icons/fa';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux'; 
import CustomAxios from '../../API/CustomAxios';


function Profile() {
  const username= useSelector((state) => state.signup.username);  // Access the username from the Redux store
  const [userRole,setUserRole] = useState(useSelector((state) => state.auth.userRole));//access the uer role form redux
  const [userId, setUserID] = useState(useSelector((state)=>state.auth.userID));

  const API_BASE = process.env.REACT_APP_API_BASE;
  const API_BASE_SMARTSCAP = process.env.REACT_APP_API_SMARTSCAP;
  const API_BASE_SMARTAJAP = process.env.REACT_APP_API_SMARTAJAP;
  const API_BASE_NORMALSCAP = process.env.REACT_APP_API_NORMALSCAP;
  const API_BASE_NORMALAJAP = process.env.REACT_APP_API_NORMALAJAP;

  const [formData, setFormData] = useState({
    profilePhoto: null,
    username: '',
    name: '',
    address: '',
    contactno: '',
    nic: '',
    email: '',
    brandName:"",
  });
 
  const validate = Yup.object({
    username: Yup.string().required('*Required'),
    name: Yup.string().required('*Required'),
    address: Yup.string().required('*Required'),
    contactno: Yup.string().required('*Required'),
    nic: Yup.string().required('*Required'),
    email: Yup.string().required('*Required').email('*Invalid email'),
  });


  useEffect(() => {
    userRole === "supplier" ? fetchSupplierProfileData() : userRole === "farmer" ? fetchProfileData() : fetchAdminProfileData();
  }, [username]); // Refetch when the username changes


  const handlePhotoUpload = (e) => {
    setFormData((prev) => ({
      ...prev,
      profilePhoto: URL.createObjectURL(e.target.files[0]),
    }));
  };


//fetch profile details of farmer
  const fetchProfileData = async () => {
    if (!username) return; // Don't fetch if there's no username
    try {

      const response = await CustomAxios({
        method: "GET",
        baseURL: API_BASE_NORMALSCAP,
        url: `api/v1/user/${userId}`,
        headers: {
          "Content-Type": "application/json"
        },
      })
   
      if(response.status === 200){
       console.log("farmer profile data",response.data.farmer);
       const data = response.data.farmer;
        setFormData({
          username: data.username,
          name:data.name,
          address: data.address,
          contactno: data.contactno,
          nic: data.uid,
          email: data.email,
        })
      }
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };


  //fetch supplier profile details
  const fetchSupplierProfileData = async()=>{
    try{
      const response = await CustomAxios({
        method: "GET",
        baseURL: API_BASE_NORMALAJAP,
        url: `api2/v2/supplier/${username}`,
        headers: {
          "Content-Type": "application/json"
        },
      })
      if(response.status === 200){
        const data = response.data
        setFormData({
          username: data.username,
          name:data.name,
          address: data.address,
          contactno: data.contactNo,
          nic: data.nic,
          email: data.email,
          brandName:data.brandName,
        })
      }
    }catch(error){
      console.log(error);
    }
  }


  //fetch profile details of admin 
  const fetchAdminProfileData =async()=>{
    try{
      const response = await CustomAxios({
        method: "GET",
        baseURL: API_BASE,
        url: "",
        headers: {
          "Content-Type": "application/json"
        },
      })
      if(response.status === 200){
        console.log("profile fetch details of admin",response.data);
      }
    }catch(error){
      console.log(error);
    }
  }


  // Handle profile update
  const handleprofileUpdate =(values) => {
    userRole === "supplier" ? handleSupplierUpdate(values) : userRole === "admin" ? handleAdminUpdate(values) : handleFarmerUpdate(values);
  };
  
  
  //handle profile update of supplier
  const handleSupplierUpdate =async(values)=>{
    if (!username) return; 
    try{
      const response = await CustomAxios({
        method: "PUT",
        baseURL: API_BASE_NORMALAJAP,
        url: `api2/v2/supplier/${username}`,
        data: values,
        headers: {
          "Content-Type": "application/json"
        },
      })

      if(response.status === 200){
        // console.log("updated supplier details",response.data);
        fetchSupplierProfileData();
      }

    }catch(error){
      console.log(error);
    }
  }


  //handle profile update for farmer
  const handleFarmerUpdate = async(values)=>{
    console.log("farmer profile update", values);
    if (!username) return; // Don't submit if there's no username
    try {
      const response = await fetch(`https://scapitwo.bethel.network/api/v1/farmer/${username}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error('Failed to update profile');
      }

      const result = await response.json();
      console.log('Profile updated successfully:', result);
      fetchProfileData(); // Optionally fetch data again to refresh
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  }


  // handle profile update for admin
  const handleAdminUpdate = async(values)=>{
    console.log("profile updating values for admin", values);
    if (!username) return; 
    try{
      const response = await CustomAxios({
        method: "GET",
        baseURL: API_BASE,
        url: "",
        headers: {
          "Content-Type": "application/json"
        },
      })

      if(response.ok){
        console.log(response.data);
        fetchAdminProfileData();
      }
    }catch(error){
      console.log(error);
    }
  }



  return (
    <div className="flex flex-col w-full h-full justify-center items-center bg-gray-100">
      <div className="flex flex-col bg-white lg:w-[750px] md:w-[700px] sm:w-[400px] min-[320px]:w-[320px]  items-center bg-gray-300/20 shadow-md justify-center rounded-md relative">
        <div className="bg-light_green/70 rounded-t-lg w-full flex justify-center items-center h-24 relative">
          <img
            src={formData.profilePhoto || 'https://via.placeholder.com/100'}
            alt="Profile"
            className="absolute top-[-50px] w-24 h-24 rounded-full object-cover"
          />
          <label className="  bottom-0 left-5 bg-light_green p-2 rounded-full text-white cursor-pointer">
            <FaCamera className="text-lg absolute" />
            <input type="file" className="hidden" onChange={handlePhotoUpload} />
          </label>
        </div>

        <h1 className="text-xl font-bold p-2 text-center text-black/70">{formData.name}</h1>
        <Formik
          initialValues={formData}
          validationSchema={validate}
          onSubmit={handleprofileUpdate}
          enableReinitialize // This allows Formik to update with the fetched data
        >
          {() => (
            <Form className="w-full flex flex-col py-4 px-12">
              {/* Username Field */}
              <div className="flex flex-col text-[14px] h-12 mb-4">
                <div className="flex flex-row gap-12 items-center">
                  <label className="w-32 text-right font-semibold">User name</label>
                  <div className="w-full flex flex-col">
                    <Field
                      type="text"
                      id="username"
                      name="username"
                      className="w-full p-2 text-[12px] text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                    />
                    <ErrorMessage
                      name="username"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div>

              {/* Name Field */}
              <div className="flex flex-col text-[14px] h-12 mb-4">
                <div className="flex flex-row gap-12 items-center">
                  <label className="w-32 text-right font-semibold">Name</label>
                  <div className="w-full flex flex-col">
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      className="w-full p-2 text-[12px] text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div>


            {/* brand name Field */}
              <div className={` ${userRole === "supplier" ? "" :"hidden"} flex flex-col text-[14px] h-12 mb-4`}>
                <div className="flex flex-row gap-12 items-center">
                  <label className="w-32 text-right font-semibold">Brand Name</label>
                  <div className="w-full flex flex-col">
                    <Field
                      type="text"
                      id="brandName"
                      name="brandName"
                      className="w-full p-2 text-[12px] text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                    />
                    <ErrorMessage
                      name="brandName"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div>


              {/* Address Field */}
              <div className="flex flex-col text-[14px] h-12 mb-4">
                <div className="flex flex-row gap-12 items-center">
                  <label className="w-32 text-right font-semibold">Address</label>
                  <div className="w-full flex flex-col">
                    <Field
                      type="text"
                      id="address"
                      name="address"
                      className="w-full p-2 text-[12px] text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div>

              {/* Phone Field */}
              <div className="flex flex-col text-[14px] h-12 mb-4">
                <div className="flex flex-row gap-12 items-center">
                  <label className="w-32 text-right font-semibold">contact No.</label>
                  <div className="w-full flex flex-col">
                    <Field
                      type="text"
                      id="contactno"
                      name="contactno"
                      className="w-full p-2 text-[12px] text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                    />
                    <ErrorMessage
                      name="contactno"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div>

              {/* NIC Field */}
              <div className="flex flex-col text-[14px] h-12 mb-4">
                <div className="flex flex-row gap-12 items-center">
                  <label className="w-32 text-right font-semibold">UID</label>
                  <div className="w-full flex flex-col">
                    <Field
                      type="text"
                      id="nic"
                      name="nic"
                      className="w-full p-2 text-[12px] text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                    />
                    <ErrorMessage
                      name="nic"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div>

              {/* Email Field */}
              <div className="flex flex-col text-[14px] h-12 mb-4">
                <div className="flex flex-row gap-12 items-center">
                  <label className="w-32 text-right font-semibold">Email</label>
                  <div className="w-full flex flex-col">
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      className="w-full p-2 text-[12px] text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div>

            <div className='flex items-center flex-row justify-center'>
                <button type="submit" className='bg-light_green p-2 text-black  w-64 flex items-center justify-center rounded-md font-semibold'>Update</button>
            </div>
              
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Profile;
