import axios from "axios"; 
import {store} from '../Pages/ReduxManagement/store';
import { useSelector } from "react-redux";
const CustomAxios = axios.create({});
const API_BASE = process.env.REACT_APP_API_BASE;


CustomAxios.interceptors.request.use(
    (req) => {
      const accessToken = store.getState().auth.REFRESHTOKEN;
      return req;
    },
  
    (err) => {
      return Promise.reject(err);
    }
  );
  
  //sets up a response interceptor for custom axios
  //allowds to modify incoming responses before they are handled by .then() and .catch()
  CustomAxios.interceptors.response.use(
    (res) => {
      return res; //returns the response object unchanged
    },
  
    (err) => {
      //recieves the error callback function for the response. recieve er obj and handle HTTP responses
      const originalReq = err.config; //recieve the original request configuration from the error obj 'err'
      const status = err.response ? err.response.status : null; //extracts the err response if it exists
      // if (status === 401) {
      //   //unauthorized due to an expired token
      //   return axios({
      //     method: "POST",
      //     baseURL: API_BASE,
      //     url: "tokens/renew_access",
      //     data: {
      //       refresh_token: localStorage.getItem("REFRESHTOKEN"),
      //     },
      //   })
      //     .then((response) => {
      //       // localStorage.setItem("REFRESHTOKEN", response.data.refresh_token);
      //       sessionStorage.setItem("ACCESSTOKEN", response.data.access_token);
      //       console.log(
      //         response,
      //         "*************Get Access Token response*******************"
      //       );
      //       return CustomAxios(originalReq);
      //     })
  
      //     .catch((error) => {
      //       console.log(error);
      //     });
      // } else if (status === 403) {
        // sessionStorage.clear();
        // localStorage.clear();
        // window.location.replace("/");
   if (status === 404) {
        return Promise.reject(err);
      }
      return Promise.resolve(err);
    }
  );
  
  export default CustomAxios;
  