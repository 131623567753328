import React, { useState } from 'react';
import FarmersUserList from '../../UserManagement/FarmersUserList';
import SuppliersUserList from '../../UserManagement/SuppliersUserList';
import UserRequests from '../../UserManagement/UserRequests';


function AdminDashboard() {
  const [usertype, setUserType] = useState("requests");
 // const [userRole,setUserRole] = useState(useSelector((state) => state.auth.userRole));

  return (
    <>
      {/* <h1 className="flex text-start font-semibold text-2xl mt-2 text-gray-500 tracking-wider mob2:ml-8 lg:ml-0">Overview</h1> */}

      {/* The tabs for farmer and the supplier to switch between them */}
      <div className="flex flex-row mt-6 gap-8 text-[15px] mb-2">
        
        <button className={`${usertype === "requests" ? "bg-agro_lightgreen/70 text-agro_darkgreen" : "bg-none"} hover:bg-white hover:text-agro_darkgreen px-2 py-1 cursor-pointer rounded-md`}
          onClick={() => setUserType("requests")}
        >
          Requests
        </button>

        <button className={`${usertype === "farmers" ? "bg-agro_lightgreen/70 text-agro_darkgreen" : "bg-none"} hover:bg-white hover:text-agro_darkgreen px-2 py-1 cursor-pointer rounded-md`}
          onClick={() => setUserType("farmers")}
        >
          FARMERS
        </button>

        <button className={`${usertype === "suppliers" ? "bg-agro_lightgreen/70 text-agro_darkgreen" : "bg-none"} hover:bg-white hover:text-agro_darkgreen px-2 py-1 cursor-pointer rounded-md`}
          onClick={() => setUserType("suppliers")}
        >
          SUPPLIERS
        </button>
      </div>

      <hr className="mx-2 mb-2 mt-2" />

      {usertype === "farmers" && <FarmersUserList />}
      {usertype === "suppliers" && <SuppliersUserList />}
      {usertype === "requests" && <UserRequests />}
    </>
  );
}

export default AdminDashboard;
