// PaddyCultivationChart.jsx
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const PaddyCultivationChart = () => {
  // Sample data for monthly paddy cultivation (in tons)
  const data = {
    labels: [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ],
    datasets: [
      {
        label: 'Paddy Cultivation (tons)',
        data: [150, 450, 200, 360, 450, 600, 750, 300, 600, 360, 800, 900],
        backgroundColor: 'rgba(34, 197, 94, 0.7)',
        borderColor: 'rgba(34, 197, 94, 1)',
        borderWidth: 5,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 100, // Adjusts the step size of the y-axis
        },
        min: 0, // Sets minimum value of y-axis
        max: 1000, // Sets maximum value of y-axis to give more space to bars
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Annual Paddy Harvest',
      },
    },
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-4  lg:w-1/2 md:w-1/2 sm:w-full min-[320px]:w-full">
      <Bar data={data} options={options} />
    </div>
  );
};

export default PaddyCultivationChart;
