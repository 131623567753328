import React,{useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Alert from '../../Components/Alert';
import { FaCamera } from 'react-icons/fa';
import CustomAxios from '../../API/CustomAxios';
import * as Yup from 'yup';


function NewProfile() {
    const username= useSelector((state) => state.signup.username);  // Access the username from the Redux store
    const [userRole,setUserRole] = useState(useSelector((state) => state.auth.userRole));//access the uer role form redux
    const [userId, setUserID] = useState(useSelector((state)=>state.auth.userID));
    const API_BASE = process.env.REACT_APP_API_BASE;
    const API_BASE_SMARTSCAP = process.env.REACT_APP_API_SMARTSCAP;
    const API_BASE_SMARTAJAP = process.env.REACT_APP_API_SMARTAJAP;
    const API_BASE_NORMALSCAP = process.env.REACT_APP_API_NORMALSCAP;
    const API_BASE_NORMALAJAP = process.env.REACT_APP_API_NORMALAJAP;
    const refreshToken = useSelector((state) => state.auth.REFRESHTOKEN);
    const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });


    //hide alerts
    const hideAlert = () => {
      setAlertProps({ ...alertProps, show: false });
    };
    
  
    const [formData, setFormData] = useState({
        profilePhoto: null,
        username: '',
        name: '',
        address: '',
        contactno: '',
        nic: '',
        email: '',
        brandName:"",
      });

      const validate = Yup.object({
        username: Yup.string().required('*Required'),
        name: Yup.string().required('*Required'),
        address: Yup.string().required('*Required'),
        contactno: Yup.string().required('*Required'),
        nic: Yup.string().required('*Required'),
        email: Yup.string().required('*Required').email('*Invalid email'),
      });


      const passwordValidate = Yup.object({
        currentpassword:Yup.string()
        .required("*Required"),
        password:Yup.string()
        .min(8, "Password must be at least 8 characters")
        .max(100, "Must be less than 100 characters")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(/[@_]/, "Password must contain at least one symbol (@ or _)")
        .required("*Password is Required"),
        confirmpassword:Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'), 
      })


      //get the user profile data 
      useEffect(() => {
        userRole === "supplier" ? fetchSupplierProfileData() : userRole === "farmer" ? fetchProfileData() : fetchAdminProfileData();
      }, [username]); // Refetch when the username changes

    
      // Handle profile update
      const handleprofileUpdate =(values) => {
       userRole === "supplier" ? handleSupplierUpdate(values) : userRole === "admin" ? handleAdminUpdate(values) : handleFarmerUpdate(values);
      };

      //handle the password update
      const handlePasswordUpdate =(values)=>{
        userRole === "admin" || "farmer" ? handleUserPasswordUpdate(values) :  handleSupplierPasswordUpdate(values);
      }

      const handleUserPasswordUpdate = async(values)=>{
        const {confirmpassword, ...passwordvalues} = values;
        try{
          const response = await CustomAxios({
            method: "PUT",
            baseURL: API_BASE_NORMALSCAP,
            url: `user/changepassword/${userId}`,
            data:passwordvalues,
            headers: {
              "Content-Type": "application/json",
              "token": refreshToken,
            },
          })
          if(response.status === 200){
            setAlertProps({severity: "success",message: response.data.message,show: true,});
          }else{
            setAlertProps({severity: "error",message: response.response.data.error,show: true,});
          }
        }catch(error){
          // console.log(error);
          const errorMessage = error.response?.data?.error || "An unexpected error occurred.";
          setAlertProps({ severity: "error", message: errorMessage, show: true });
        }
      }


      const handleSupplierPasswordUpdate = async()=>{
        try{
          const response = await CustomAxios({
            method: "GET",
            baseURL: API_BASE_NORMALSCAP,
            url: `user/${userId}`,
            headers: {
              "Content-Type": "application/json",
              "token": refreshToken,
            },
          })
          if(response.status === 200){

          }
        }catch(error){
          console.log(error);
        }
      }



    //fetch profile details of farmer
    const fetchProfileData = async () => {
      try {
        const response = await CustomAxios({
          method: "GET",
          baseURL: API_BASE_NORMALSCAP,
          url: `user/${userId}`,
          headers: {
            "Content-Type": "application/json",
            "token": refreshToken,
          },
        })
    
        if(response.status === 200){
        // console.log("farmer profile data",response.data.user);
        const data = response.data.user;
          setFormData({
            username: data.username,
            name:data.name,
            address: data.address,
            contactno: data.contactno,
            nic: data.uid,
            email: data.email,
          })
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };


        //fetch supplier profile details
        const fetchSupplierProfileData = async()=>{
            try{
            const response = await CustomAxios({
                method: "GET",
                baseURL: API_BASE_NORMALAJAP,
                url: `api2/v2/supplier/${username}`,
                headers: {
                "Content-Type": "application/json"
                },
            })
            if(response.status === 200){
                const data = response.data
                setFormData({
                username: data.username,
                name:data.name,
                address: data.address,
                contactno: data.contactNo,
                nic: data.nic,
                email: data.email,
                brandName:data.brandName,
                })
            }
            }catch(error){
            console.log(error);
            }
        }


        //fetch profile details of admin 
        const fetchAdminProfileData =async()=>{
            try{
            const response = await CustomAxios({
                method: "GET",
                baseURL: API_BASE_NORMALSCAP,
                url: `admin/${userId}`,
                headers: {
                "Content-Type": "application/json",
                "token": refreshToken,
                },
            })
            if(response.status === 200){
                // console.log("profile fetch details of admin",response.data);
                const data = response.data.user;
                setFormData({
                  username: data.username,
                  name:data.name,
                  address: data.address,
                  contactno: data.contactno,
                  nic: data.uid,
                  email: data.email,
                })
            }
            }catch(error){
            console.log(error);
            }
        }

        //handle profile update of supplier
        const handleSupplierUpdate =async(values)=>{
            if (!username) return; 
            try{
            const response = await CustomAxios({
                method: "PUT",
                baseURL: API_BASE_NORMALAJAP,
                url: `api2/v2/supplier/${username}`,
                data: values,
                headers: {
                "Content-Type": "application/json"
                },
            })

            if(response.status === 200){
                // console.log("updated supplier details",response.data);
                fetchSupplierProfileData();
            }

            }catch(error){
            console.log(error);
            }
        }


        //handle profile update for farmer
        const handleFarmerUpdate = async(values)=>{
            console.log("farmer profile update", values);
            if (!username) return; // Don't submit if there's no username
            try {
            const response = await fetch(`https://scapitwo.bethel.network/api/v1/farmer/${username}`, {
                method: 'PUT',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });

            if (!response.ok) {
                throw new Error('Failed to update profile');
            }

            const result = await response.json();
            console.log('Profile updated successfully:', result);
            fetchProfileData(); // Optionally fetch data again to refresh
            } catch (error) {
            console.error('Error updating profile:', error);
            }
        }
        

        // handle profile update for admin
        const handleAdminUpdate = async(values)=>{
            try{
            const response = await CustomAxios({
                method: "PUT",
                baseURL: API_BASE_NORMALSCAP,
                url: `admin/${userId}`,
                data:values,
                headers: {
                "Content-Type": "application/json",
                "token": refreshToken,
                },
            })
            if(response.ok){
                fetchAdminProfileData();
            }
            }catch(error){
            console.log(error);
            }
        }


      const handlePhotoUpload = (e) => {
        setFormData((prev) => ({
          ...prev,
          profilePhoto: URL.createObjectURL(e.target.files[0]),
        }));
      };
    

  return (
    <div className='flex flex-row justify-center items-center min-h-[85vh] w-full lg:mt-6 mob2:mt-6 xl:mt-0'>
        <div className=' grid lg:grid-cols-1 md:grid-cols-3 mob2:grid-cols-1 xl:grid-cols-3 xl:gap-8 lg:gap-4 md:gap-4 mob2:gap-4  h-full w-full'>
            <div className='flex xl:flex-col sm:flex-row mob2:flex-col md:flex-col xl:col-span-1 md:col-span-1 sm:col-span-1 rounded-lg shadow-md xl:p-4 lg:p-6 md:p-4 sm:p-4 mob2:p-4 h-full bg-white items-center gap-8'>
                <div className='lg:h-48 lg:w-48  md:w-24 md:h-24 sm:w-72 sm:h-48  mob2:w-48 mob2:h-48 rounded-full bg-white flex justify-center relative border '>
                    <img src={formData.profilePhoto} className="h-full w-full object-cover rounded-full" />
                    <label className="absolute -bottom-4 bg-agro_greenlight p-2 rounded-full text-white cursor-pointer">
                        <FaCamera className="text-lg" />
                        <input type="file" className="hidden" onChange={handlePhotoUpload} />
                    </label>
                </div>

            <div className='flex flex-col justify-end items-start w-full'>
                <div className='flex flex-col justify-center items-center w-full mt-2'>
                    <h3 className='text-start mb-4'>Password Change</h3>
                    <Formik
                      initialValues={{
                        currentpassword:"",
                        password:"",
                        confirmpassword:"",
                      }}
                      validationSchema={passwordValidate}
                      onSubmit={handlePasswordUpdate}
                    >
                      {(formik) =>(
                        <Form className='flex flex-col w-full xl:px-12 lg:px-4 gap-2 items-center'>
                          <div className='flex flex-col w-full'>
                            <Field
                                type="password"
                                id="currentpassword"
                                name="currentpassword"
                                placeholder="Current Password"
                                className="p-2 text-[12px] w-full text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                              /> 
                            <ErrorMessage 
                              name="currentpassword" 
                              component="div" 
                              className="text-red-500 text-[9px] font-medium italic " 
                            />                           
                          </div>
  
                          <div className='flex flex-col w-full'>
                            <Field
                              type="password"
                              id="password"
                              name="password"
                              placeholder="Password"
                              className="p-2 text-[12px] w-full text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                            />
                            <ErrorMessage 
                              name="currentpassword" 
                              component="div" 
                              className="text-red-500 text-[9px] font-medium italic " 
                            />
                          </div>

                          <div className='flex flex-col w-full'>
                          <Field
                              type="password"
                              id="confirmpassword"
                              name="confirmpassword"
                              placeholder="Confirm Password"
                              className="p-2 text-[12px] w-full text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                            />
                          <ErrorMessage 
                              name="currentpassword" 
                              component="div" 
                              className="text-red-500 text-[9px] font-medium italic " 
                            />
                          </div>
                            <button tyep="submit" className='bg-agro_greenlight w-48 mt-4 text-white rounded-md p-2 '>Update</button>
                        </Form>
                      )}
                    </Formik>
                </div>
            </div>
            </div>


      <div className='flex flex-col xl:col-span-2 lg:col-span-1 md:col-span-2 h-full'>
            <Formik
                initialValues={formData}
                validationSchema={validate}
                onSubmit={handleprofileUpdate}
                enableReinitialize // This allows Formik to update with the fetched data
            >
          {(formik) => (
            <Form className="w-full flex flex-col py-8 px-12 rounded-lg h-full bg-white shadow-md ">
              {/* Username Field */}
              <div className="flex flex-col w-full text-[14px] h-18 mb-4">
                <div className="flex sm:flex-row mob2:flex-col sm:gap-12 sm:items-center mob2:items-start mob2:gap-1">
                  <label className="w-32 sm:text-right font-semibold mob:justify-start mob:flex">User name</label>
                  <div className="w-full flex flex-col">
                    <Field
                      type="text"
                      id="username"
                      name="username"
                      className="w-full p-2 text-[12px] text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                    />
                    <ErrorMessage
                      name="username"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div>

              {/* Name Field */}
              <div className="flex flex-col text-[14px] h-18 mb-4">
                <div className="flex sm:flex-row mob2:flex-col sm:gap-12 sm:items-center mob2:items-start mob2:gap-1">
                  <label className="w-32 sm:text-right font-semibold mob:justify-start mob:flex">Name</label>
                  <div className="w-full flex flex-col">
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      className="w-full p-2 text-[12px] text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div>


            {/* brand name Field */}
              <div className={` ${userRole === "supplier" ? "" :"hidden"} flex flex-col text-[14px] h-18 mb-4`}>
                <div className="flex sm:flex-row mob2:flex-col sm:gap-12 sm:items-center mob2:items-start mob2:gap-1">
                  <label className="w-32 sm:text-right font-semibold mob:justify-start mob:flex">Brand Name</label>
                  <div className="w-full flex flex-col">
                    <Field
                      type="text"
                      id="brandName"
                      name="brandName"
                      className="w-full p-2 text-[12px] text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                    />
                    <ErrorMessage
                      name="brandName"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div>


              {/* Address Field */}
              <div className="flex flex-col text-[14px] h-18 mb-4">
                <div className="flex sm:flex-row mob2:flex-col sm:gap-12 sm:items-center mob2:items-start mob2:gap-1">
                  <label className="w-32 sm:text-right font-semibold mob:justify-start mob:flex">Address</label>
                  <div className="w-full flex flex-col">
                    <Field
                      type="text"
                      id="address"
                      name="address"
                      className="w-full p-2 text-[12px] text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div>

              {/* Phone Field */}
              <div className="flex flex-col text-[14px] h-18 mb-4">
                <div className="flex sm:flex-row mob2:flex-col sm:gap-12 sm:items-center mob2:items-start mob2:gap-1">
                  <label className="w-32 sm:text-right font-semibold mob:justify-start mob:flex">contact No.</label>
                  <div className="w-full flex flex-col">
                    <Field
                      type="text"
                      id="contactno"
                      name="contactno"
                      className="w-full p-2 text-[12px] text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                    />
                    <ErrorMessage
                      name="contactno"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div>

              {/* NIC Field */}
              {/* <div className="flex flex-col text-[14px] h-18 mb-4">
                <div className="flex sm:flex-row mob2:flex-col sm:gap-12 sm:items-center mob2:items-start mob2:gap-1">
                  <label className="w-32 sm:text-right font-semibold mob:justify-start mob:flex">UID</label>
                  <div className="w-full flex flex-col">
                    <Field
                      type="text"
                      id="nic"
                      name="nic"
                      className="w-full p-2 text-[12px] text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                    />
                    <ErrorMessage
                      name="nic"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div> */}

              {/* Email Field */}
              <div className="flex flex-col text-[14px] h-18 mb-4">
                <div className="flex sm:flex-row mob2:flex-col sm:gap-12 sm:items-center mob2:items-start mob2:gap-1">
                  <label className="w-32 sm:text-right font-semibold mob:justify-start mob:flex">Email</label>
                  <div className="w-full flex flex-col">
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      className="w-full p-2 text-[12px] text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div>

            <div className='flex items-center flex-row justify-center'>
                <button type="submit" className='bg-agro_greenlight p-2 text-white  w-64 flex items-center justify-center rounded-md '>Update</button>
            </div>
              
            </Form>
          )}
        </Formik>
    </div>
    </div>
        {/* Calling the Alert component */}
        <Alert
          message={alertProps.message}
          severity={alertProps.severity}
          show={alertProps.show}
          onClose={hideAlert}
       />
    </div>
  )
}

export default NewProfile