import React from 'react'
import SupplierSales from './SupplierSales'
import SupplierIncome from './SupplierIncome'
import CurrentOrderList from '../../Orders/Supplier/CurrentOrderList'
import Header from '../../../Components/Header'

function SupplierDashboard() {
  return (
  <>
  {/* <Header heading="Overview"/> */}

  <div className='flex flex-col gap-6 w-full'>
    <div className='grid xl:grid-cols-2 mt-6 gap-6 grid-cols-1 '>
      <SupplierSales/>
      <SupplierIncome />
    </div>

    <CurrentOrderList />
  </div>
  </>
  )
}

export default SupplierDashboard