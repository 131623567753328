import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import CustomAxios from '../../API/CustomAxios';
import { setUserRole, setUserID } from '../ReduxManagement/authSlice';
import { useDispatch } from 'react-redux';
import { setSignupUsername } from '../ReduxManagement/signupSlice';
import { setHeaderContent } from '../ReduxManagement/headercontentSlice';
import { setToken } from '../ReduxManagement/authSlice';
import { setAddress } from '../ReduxManagement/authSlice';
import Alert from '../../Components/Alert';


function Login() {
  const dispatch = useDispatch();
  const API_BASE = process.env.REACT_APP_API_BASE;
  const API_BASE_SMARTSCAP = process.env.REACT_APP_API_SMARTSCAP;
  const API_BASE_SMARTAJAP = process.env.REACT_APP_API_SMARTAJAP;
  const API_BASE_NORMALSCAP = process.env.REACT_APP_API_NORMALSCAP;
  const API_BASE_NORMALAJAP = process.env.REACT_APP_API_NORMALAJAP;
  const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });
  const navigate = useNavigate();
 
 
  //hide alerts
  const hideAlert = () => {
    setAlertProps({ ...alertProps, show: false });
  };
  

 //handling the login 
  const handleLoginSubmit =async(values)=>{
    try{
      const response =  await CustomAxios({
        method:"POST",
        baseURL:API_BASE_NORMALSCAP,
        url:"user/login",
        data: values,
        headers:{
          "Content-Type":"application/json"
        },
      })
      if(response.status === 200){
        setAlertProps({message: "Login is successfull!",severity: "success",show: true});
        const newusername = response.data.user.username;
        const userRole =  response.data.user.role;
        const userID = response.data.user.uid;
        const refreshToken = response.data.token.refresh;
        const myaddress = response.data.user.address;

        dispatch(setUserRole(userRole));
        dispatch(setSignupUsername(newusername));
        dispatch(setToken(refreshToken));
        dispatch(setHeaderContent("Overview"));
        dispatch(setUserID(userID));
        dispatch(setAddress(myaddress));

        setTimeout(()=>{
            navigate("/dashboard");
        },2000) 

      }else{
        setAlertProps({severity: "error",message: response.response.data.error,show: true,});
      }
    }catch(error){
      const errorMessage = error.response?.data?.error || "An unexpected error occurred.";
      setAlertProps({ severity: "error", message: errorMessage, show: true });
      
    }
  }

  //validation for login
  const validate = Yup.object({
    username:Yup.string()
    // .min(8, "Must be more than 8 characters")
    // .max(100, "Must be less than 100 characters")
    // .matches( /^[a-z0-9@_]+$/,"*Username must include at least one number and can only contain lowercase letters, @, and _")
    .required("*Required"),
    password:Yup.string()
    // .min(8, "Password must be at least 8 characters")
    // .max(100, "Must be less than 100 characters")
    // .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    // .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    // .matches(/[0-9]/, "Password must contain at least one number")
    // .matches(/[@_]/, "Password must contain at least one symbol (@ or _)")
    .required("*Password is Required"),
  })

  return (
    <div className='flex flex-col w-full '>

      {/* samll description */}
      <div className='md:mt-8  md:text-justify mob2:text-center mob:mt-4 mob2:mt-8'>
        <h1 className='font-semibold md:text-[18px] mob2:text-[20px] '>Welcome Back</h1>
        <p className='md:text-xs sm:text-[13px] mob2:text-[12px] mt-2 text-black/50 tracking-wider'>All are welcomed to our Agro Chain managemnt platform, which manages all the functionalities in Supply chain of Paddy Plant.</p>
      </div>

      <Formik
        initialValues={{
           username:"",
           password:""
        }}
        validationSchema={validate}
        onSubmit={handleLoginSubmit}
      >
        {(formik) => (
          <Form className='md:mt-12 mob2:mt-12 mob:mt-8 font-normal text-[12px] w-full flex flex-col gap-3 mob2:px-12 md:px-0'>
            {/* The field for company id */}
            <div className='flex flex-col h-20'>
              <label>Username</label>
              <Field 
                type="text"
                id="username"
                name="username"
                onChange={formik.handleChange}
                value={formik.values.username}
                className='border w-full mt-1  focus:outline-none p-3 text-normal text-[12px] text-gray-300 rounded-lg'
              />
              <ErrorMessage 
                name="username" 
                component="div" 
                className="text-red-500 text-[9px] font-medium italic mt-1" 
              />
            </div>

            {/* The field for password */}
            <div className='flex flex-col h-20 '>
              <label>Password</label>
              <Field 
                type="password"
                id="password"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                className='border w-full mt-1 focus:outline-none p-3 text-normal text-[12px] text-gray-300 rounded-lg'
              />
              <ErrorMessage 
                name="password" 
                component="div" 
                className="text-red-500 text-[9px] font-medium italic mt-1" 
              />
            </div>

            <div className='mt-8 flex flex-col items-center gap-4'>
              <button
                type ="submit"
                className='bg-agro_darkgreen font-semibold w-full text-white p-2  text-[13px] rounded-lg'
              >
                LogIn
              </button>
              <h3 className='font-light text-black/50  md:text-[12px] sm:text-[13px]'>Not Registered yet?
                <button
                  type="button"
                  onClick={ ()=>navigate("/homesignup")}
                  className='text-agro_darkgreen font-bold ml-1 md:text-[13px]'
                > 
                  Create an Account
                </button> 
              </h3>
            </div>
          </Form>
        )}
      </Formik>

      {/* Calling the Alert component */}
      <Alert
          message={alertProps.message}
          severity={alertProps.severity}
          show={alertProps.show}
          onClose={hideAlert}
       />

    </div>
  )
}

export default Login