import React, { useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Header from "../../Components/Header";
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Dashboard() {
  const [showsidebar, setShowSidebar] = useState(false);
  // const [headerContent, setHeaderContent] = useState("Overview"); // initialize with default content
 
  return (
    <div className="p-2 w-full h-screen bg-agro_greenlight/60 relative">
      <div className="flex flex-row bg-white w-full h-full rounded-lg relative">
        {/* Sidebar */}
        <div className={`sm:w-[240px] h-full bg-white rounded-lg lg:relative lg:block ${showsidebar ? 'mob2:block mob2:absolute mob2:top-0 mob2:left-0 mob2:w-full z-20' : 'mob2:hidden'}`}>
          <Sidebar setShowSidebar={setShowSidebar} /> {/* Pass setHeaderContent */}
        </div>

        <div className="w-full h-full flex-col bg-agro_greengray/20 rounded-lg overflow-y-auto no-scrollbar">
          {/* Header and Main Content */}
          <Header showsidebar={showsidebar} setShowSidebar={setShowSidebar}/> 
          
          <div className="px-4">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
