import React from 'react'
import ShoppingCart from './ShoppingCart'
import ProductMainCard from './ProductMainCard'

function ProductList() {
  return (
    <div className='flex lg:flex-row md:flex-col sm:flex-col min-[320px]:flex-col gap-x-3 gap-y-3'>
      <div className='lg:w-1/3 md:w-full sm:w-full min-[320px]:w-full items-center justify-center flex'>
          <ProductMainCard/>
      </div>
      <div className='lg:w-2/3 md:w-full sm:w-full min-[320px]:w-full'>
          <ShoppingCart/>
      </div>
    </div>
  )
}

export default ProductList
