import React, { useEffect, useState } from 'react';
import TablePagination from '@mui/material/TablePagination';
import edit from '../../Assests/edit.png';
import deleteimg from '../../Assests/delete.png';
import correct from "../../Assests/correct.png";
import CustomAxios from '../../API/CustomAxios';
import { useSelector } from 'react-redux';
import Alert from "../../Components/Alert";


function UserRequests (){
    const API_BASE_SMARTSCAP = process.env.REACT_APP_API_SMARTSCAP;
    const API_BASE_SMARTAJAP = process.env.REACT_APP_API_SMARTAJAP;
    const API_BASE_NORMALSCAP = process.env.REACT_APP_API_NORMALSCAP;
    const API_BASE_NORMALAJAP = process.env.REACT_APP_API_NORMALAJAP;
    const [displayList, setDisplayList] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const refreshToken = useSelector((state)=>state.auth.REFRESHTOKEN);
    const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });


    //hide alerts
    const hideAlert = () => {
      setAlertProps({ ...alertProps, show: false });
    };
    
    const requestlist = [
        { sup_Id: "45892", name: "sunil", username: "sunil@123", email: "sunil@gmail.com", status: "Active", verification: "Verify", phone: "0759876483" },
        { sup_Id: "752561", name: "Amal", username: "amal@123", email: "amal@gmail.com", status: "Deactive", verification: "Verify", phone: "0759876483" },
        { sup_Id: "72151", name: "jayasekara", username: "jaya@123", email: "jaya@gmail.com", status: "Active", verification: "NotVerify", phone: "0759876483" },
        { sup_Id: "1254", name: "kumara", username: "kumara@123", email: "kumara@gmail.com", status: "Deactive", verification: "NotVerify", phone: "0759876483" },
        { sup_Id: "1388", name: "ajith", username: "ajith@123", email: "ajith@gmail.com", status: "Active", verification: "Verify", phone: "0759876483" },
    ];

    useEffect(()=>{
        getUserRequests();
        // setDisplayList(requestlist);
    },[])


    //get the registered users that are not verified
    const getUserRequests = async()=>{
        try{
            const response = await CustomAxios({
                method:"GET",
                baseURL:API_BASE_NORMALSCAP,
                url:"listUserRegistrations",
                headers:{
                    "Content-Type":"application/json",
                    "token": refreshToken,
                }
            })

            if(response.status === 200){
                // const filteredOrders = response.data.users.filter(user =>
                //     user.OrderItems.some(item => item.orderStatus === "Pending")
                // );
                // setPendingOrders(filteredOrders);
                setDisplayList(response.data.users || []);
            }
        }catch(error){
            console.log(error);
        }
    }


    //handling the accept of farmers
    const handleVerify = async(farmerID)=>{
        try{
        const response = await CustomAxios({
            method:"PUT",
            baseURL:API_BASE_NORMALSCAP,
            url:`user/updateVerifyStatus/${farmerID}`,
            data:{
                isVerified : true,
            },
            headers:{
                 "Content-Type":"application/json",
                 "token": refreshToken,
            },
        })

        if(response.status === 200){
            setAlertProps({severity: "success",message: "Verified Successfully!",show: true,});
            setDisplayList((prevState) => prevState.map((updatingUser) => updatingUser.uid === farmerID ? {...updatingUser, isVerified:true} : updatingUser))
            getUserRequests();
        }
        }catch(error){
            console.log(error);
        }
    }


    //handle accept all 
    const handleAcceptAll =()=>{
        selectedUsers.forEach((user) =>handleVerify(user))
    }

    //handle selecting all checkboxes
    const handleSelectAllCheckboxed = ()=>{
        selectedUsers.length === displayList.length ? setSelectedUsers([]): setSelectedUsers(displayList.map((user) => user.sup_Id))
    }

    //handle when checkboxes are clicked
    const handleCheckboxChange = (supId)=>{
        setSelectedUsers((prevSelected) => prevSelected.includes(supId) ? prevSelected.filter((id) => id !== supId) : [...prevSelected, supId])
    }

    //handle the page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    //handle the rows per page
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
 return(
 <>
<div className='flex flex-col rounded-lg mt-4'>
    <div className='flex flex-col'>
        <div className= "flex flex-row items-center justify-between mb-2">
            <button onClick={handleAcceptAll} className="bg-agro_greenlight text-white text-sm p-2 px-4 rounded-md">Verify All</button>
            <TablePagination
                component="div"
                rowsPerPageOptions={[2, 10, 25, 50]}
                page={page}
                count={displayList? displayList.length :0}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> 
        </div>

    <div className='overflow-x-auto rounded-lg bg-white'>
        <table className='min-w-full rounded-lg'>
            <thead className='bg-agro_lightgreen/60 text-black/70 text-left'>
                <tr>
                    <th className='px-6 py-3 text-xs '>
                        <input
                            type="checkbox"
                            checked={selectedUsers.length === displayList.length}
                            onChange={handleSelectAllCheckboxed}
                        />
                    </th>
                    <th className='px-6 py-3 text-xs'>No</th>
                    <th className='px-6 py-3 text-xs'>Name</th>
                    <th className='px-6 py-3 text-xs'>Username</th>
                    <th className='px-6 py-3 text-xs'>Email</th>
                    <th className='px-6 py-3 text-xs'>Status</th>
                    {/* <th className='px-6 py-3 text-xs'>Verification</th> */}
                    <th className='px-6 py-3 text-xs'>Phone No</th>
                    <th className='px-6 py-3 text-xs'>Action</th>
                </tr>
            </thead>

            <tbody>
                {displayList && displayList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index)=>
                    <tr key={index} className='text-xs font-normal border border-b-agro_lightgreen/50'>
                        <td className='px-6 py-3'>
                            <input         
                            type="checkbox"
                            checked={selectedUsers.includes(user.sup_Id)}
                            onChange={() => handleCheckboxChange(user.sup_Id)}
                            />
                        </td>
                        <td className='px-6 py-3'>{page * rowsPerPage + index + 1}</td>
                        <td className='px-6 py-3'>{user.name}</td>
                        <td className='px-6 py-3'>{user.username}</td>
                        <td className='px-6 py-3'>{user.email}</td>
                        <td className='px-6 py-3'>{user.status}</td>
                        {/* <td className='px-6 py-3'>{user.verification}</td> */}
                        <td className='px-6 py-3'>{user.contactno}</td>
                        <td className='px-6 py-3'>
                            <button 
                                className='px-4 py-2 rounded-md text-black/60 font-semibold bg-agro_lightgreen/60 hover:bg-agro_lightgreen/40'
                                onClick={()=> handleVerify(user.uid)}
                            >
                                Verify
                            </button>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
    </div>
         {/* Calling the Alert component */}
         <Alert
          message={alertProps.message}
          severity={alertProps.severity}
          show={alertProps.show}
          onClose={hideAlert}
       />
</div>


</>
 );
}
export default UserRequests;