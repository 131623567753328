import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { useState } from 'react';
import CustomAxios from '../../../API/CustomAxios';


function CreateNewItem({closeModel, addItem}) {
    const [imageUrl, setImageUrl] = useState(null);
    const API_BASE = process.env.REACT_APP_API_BASE;
      
    
 //validation for adding items for a relevant product
const validate = Yup.object({
    product_name:Yup.string()
    .required("*Required"),
    price: Yup.string()
    .required("*Required"),
    stock: Yup.string()
    .required("*Required"),
    image: Yup.mixed()
    .required("*Required"),
});

//handle the add item of the relvant product
const handleAddItem =async(values)=>{
    console.log("adgsgf",values);
    try{
        const response = await CustomAxios({
            method:"POST",
            baseURL:API_BASE,
            url:"",
            data: values,
            headers:{
                "Content-Type":"application/json"
            },
        })
        addItem(response.data);

    }catch(error){
        console.log(error);
    }
      closeModel()
}

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
        <div className='bg-white p-6 rounded-lg shadow-lg w-[550px] flex flex-col items-center justify-center gap-4 relative'>
        <h1 className='text-center text-agro_green font-semibold text-lg '>Add Item</h1>
        <Formik
              initialValues={{
                product_name: "",
                price: "",
                image: "",
                stock: "",
              }}
              validationSchema={validate}
              onSubmit={handleAddItem}
            >
        {(formik) => (
          <Form className='flex flex-col gap-3'>
              
              {/* Field for product name */}
              <div className='flex flex-row items-center gap-3 h-16 w-full'>
                <label className='text-[13px] text-black/70 w-32'>Product Name</label>
                <div className="flex flex-col w-full">
                  <Field
                    type="text"
                    id="product_name"
                    name="product_name"
                    className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                  />
                  <ErrorMessage name="product_name" component="div" className="text-red-500 text-[9px] font-medium italic mt-1" />
                </div>
              </div>

              {/*field for product price */}
              <div className='flex flex-row items-center gap-3 w-full h-16'>
                <label className='text-[13px] text-black/70 w-32'>Price</label>
                <div className="flex flex-col w-full">
                  <Field
                    type="text"
                    id="price"
                    name="price"
                    className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                  />
                  <ErrorMessage name="price" component="div" className="text-red-500 text-[9px] font-medium italic mt-1" />
                </div>
              </div>


              {/* field for image */}
              <div className='flex flex-row items-center gap-3 w-full h-16'>
                <label className='text-[13px] text-black/70 w-32'>Image</label>
                <div className="flex flex-col w-full">
                  <input
                    type="file"
                    id="image"
                    name="image"
                    onChange={(event) => {
                      formik.setFieldValue("image", event.currentTarget.files[0]);
                      const reader = new FileReader();
                      reader.onload = () => {
                        setImageUrl(reader.result);
                      };
                      reader.readAsDataURL(event.currentTarget.files[0]);
                    }}
                    className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                  />
                  <ErrorMessage name="image" component="div" className="text-red-500 text-[9px] font-medium italic mt-1" />
                </div>
              </div>


              <div className='flex flex-row items-center gap-3 w-full '>
                <label className='text-[13px] text-black/70 w-32'></label>
                  <div className="flex flex-col w-full">
                  {imageUrl && (
                      <img src={imageUrl} alt="Preview" className="mt-2 w-auto  object-cover  rounded-lg" />
                  )}
                  </div>
              </div>


              {/* Field for product stock */}
              <div className='flex flex-row items-center gap-3 w-full h-16'>
                <label className='text-[13px] text-black/70 w-32'>Stock</label>
                <div className="flex flex-col w-full">
                  <Field
                    type="text"
                    id="stock"
                    name="stock"
                    className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                  />
                  <ErrorMessage name="stock" component="div" className="text-red-500 text-[9px] font-medium italic mt-1" />
                </div>
              </div>


              <button type="submit" className='mt-4 bg-agro_green text-white p-2 rounded-lg'>Add</button>              
              <div className="absolute bg-white  right-0  top-0 w-8 h-8 rounded-full">
                  <button className='w-full h-full  text-red-500 font-bold' onClick={closeModel}>X</button>
              </div>  
            </Form>
          )}
       </Formik>
        </div>
    </div>
  )
}

export default CreateNewItem