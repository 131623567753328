import React, { useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, LabelList, ResponsiveContainer } from 'recharts';
import CustomAxios from '../../../API/CustomAxios';


function SupplierIncome() {
    const API_BASE = process.env.REACT_APP_API_BASE;
    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FF4081", "#9C27B0", "#3F51B5", "#FF5722", "#4CAF50", "#795548", "#FFC107", "#607D8B"];


    const data = [
        { name: "Jan", value: 9 },
        { name: "Feb", value: 2 },
        { name: "Mar", value: 2 }, 
        { name: "Apr", value: 2 },
        { name: "May", value: 7 },
        { name: "Jun", value: 7 },
        { name: "Jul", value: 7 },
        { name: "Sep", value: 7 },
        { name: "Oct", value: 7 },
        { name: "Nov", value: 7 },
        { name: "Dec", value: 0 },
    ];
    

    useEffect(()=>{
        getIncomeDetails();
    },[])


    //get the income details of supplier
    const getIncomeDetails = async()=>{
        try{
            const response =  await CustomAxios({
                method:"GET",
                baseURL:API_BASE,
                url:"",
                headers:{
                  "Content-Type":"application/json"
                },
            })
        }catch(error){
            console.log(error);
        }
    }

  
    return (
        <div className='border bg-white shadow-md border-none rounded-md p-4 flex flex-col gap-3'>
            <h1>MONTHLY INCOME</h1>
            <ResponsiveContainer width="100%" height={250}>
            <PieChart>
                <Pie
                    data={data}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    // fill="#8884d8"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}

                    {/* Using LabelList to show values above each slice */}
                    <LabelList
                        dataKey="value"
                        position="outside"
                        offset={20} 
                        style={{
                            fontSize: '15px',
                            fontWeight: 'bold',
                            fill: '#333',
                        }}
                    />
                </Pie>
                <Tooltip 
                    wrapperStyle={{
                        fontSize: "12px"
                    }}
                />
                <Legend 
                    layout="vertical" 
                    align="right" 
                    verticalAlign="middle"
                    wrapperStyle={{
                        fontSize: '12px', 
                        lineHeight: '24px' 
                    }}
                />
            </PieChart>
        </ResponsiveContainer>
        </div>
    );
}

export default SupplierIncome;
