import React,{useEffect, useState} from 'react'
import CustomAxios from '../../../../API/CustomAxios';
import { useSelector } from 'react-redux';


function CustomerDetailsperorder({farmerID, newBillingAdress}) {
  const API_BASE = process.env.REACT_APP_API_BASE;
  const API_BASE_SMARTSCAP = process.env.REACT_APP_API_SMARTSCAP;
  const API_BASE_SMARTAJAP = process.env.REACT_APP_API_SMARTAJAP;
  const API_BASE_NORMALSCAP = process.env.REACT_APP_API_NORMALSCAP;
  const API_BASE_NORMALAJAP = process.env.REACT_APP_API_NORMALAJAP;
  const [farmerDetails, setFarmerDetails] = useState("");
  const refreshToken = useSelector((state) => state.auth.REFRESHTOKEN);
  const [userId, setUserID] = useState(useSelector((state)=>state.auth.userID));

console.log(farmerID);

  useEffect(()=>{
    getcustomerDetails();
  },[farmerID])


  //fetch the customer details by using the farmer username
  const getcustomerDetails = async()=>{
    try{
        const response = await CustomAxios({
            method: "GET",
            baseURL: API_BASE_NORMALSCAP,
            url: `user/${farmerID}`, 
            headers: {
                "Content-Type": "application/json",
                "token": refreshToken,
            },
        })
        if(response.status === 200){
          setFarmerDetails(response.data.user);
          newBillingAdress(response.data.user.address);
        }
    }catch(error){
        console.log(error);
    }
  }

  return (
    <div className=' rounded-md p-4 flex flex-col gap-6 bg-white shadow-md'>
        <h1>CUSTOMER DETAILS</h1>
        <div className='w-full text-[12px] text-black/50 flex flex-col gap-5'>
            <div className=' flex flex-row justify-between '>
                <label className='font-semibold w-2/5'>Name</label>
                <span className='w-3/5'>: {farmerDetails.name}</span>
            </div>
            <div className=' flex flex-row justify-between w-full'>
                <label className='font-semibold w-2/5'>Email</label>
                <span className='w-3/5'>: {farmerDetails.email}</span>
            </div>
            <div className=' flex flex-row justify-between w-full'>
                <label className='font-semibold w-2/5'>Contact</label>
                <span className='w-3/5'>: {farmerDetails.contactno}</span>
            </div>
            <div className=' flex flex-row justify-between w-full'>
                <label className='font-semibold w-2/5'>Address</label>
                <span className='w-3/5 flex-wrap flex'>: {farmerDetails.address}</span>
            </div>

        </div>
    </div>
  )
}

export default CustomerDetailsperorder