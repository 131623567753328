import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

function LandForm({ onSubmit, landData }) {
  // States for managing form input and mode
  const [landAddress, setLandAddress] = useState('');
  const [ownLandHectares, setOwnLandHectares] = useState('');
  const [cultivatingLandHectares, setCultivatingLandHectares] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [submittedData, setSubmittedData] = useState(null); 
  const farmerID = useSelector((state) => state.signup.username);

 
  useEffect(() => {
    if (landData) {
      setLandAddress(landData.Location);
      setOwnLandHectares(landData.LandSize);
      setCultivatingLandHectares(landData.CultivatedSize);
      setSubmittedData(landData); 
    }
  }, [landData]);

  // Function to handle create request (POST)
  const handleCreate = async () => {
    const data = {
      FarmerID: farmerID,
      Location: landAddress,
      LandSize: ownLandHectares,
      CultivatedSize: cultivatingLandHectares,
    };

    try {
      const response = await fetch('https://scapione.bethel.network/createLand', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      alert(`Data submitted successfully: ${JSON.stringify(result)}`);

      // Store LandID from response
      setSubmittedData({
        ...data,
        LandID: result.LandID, 
      });

      // Trigger the parent callback with LandID
      onSubmit({
        ...data,
        LandID: result.LandID, 
      });

      // Clear input fields after successful submit
      setLandAddress('');
      setOwnLandHectares('');
      setCultivatingLandHectares('');
    } catch (error) {
      console.error('Error:', error);
      alert(`Error: ${error.message}`);
    }
  };

  // Function to handle update request (PUT)
  const handleUpdate = async () => {
    const data = {
      LandID: submittedData.LandID, 
      FarmerID: farmerID,
      Location: landAddress,
      LandSize: ownLandHectares,
      CultivatedSize: cultivatingLandHectares,
    };

    try {
      const response = await fetch('https://scapione.bethel.network/updateLandByID', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      alert(`Data updated successfully: ${JSON.stringify(result)}`);

     
      setSubmittedData({
        ...data,
        LandID: result.LandID, 
      });

      
      onSubmit(data);
      
      setIsEditing(false);
    } catch (error) {
      console.error('Error:', error);
      alert(`Error: ${error.message}`);
    }
  };

  // Handle form submission (either create or update)
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submittedData) {
      handleUpdate(); 
    } else {
      handleCreate(); 
    }
  };

  // Toggle between view and edit mode
  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md lg:w-1/2 md:w-1/2 sm:w-full min-[320px]:w-full">
      <h2 className="text-2xl font-bold mb-4">
        {submittedData ? 'Land Information (View/Edit)' : 'Land Information'}
      </h2>

      {/* Editable Form (when no data is submitted or when editing) */}
      {(!submittedData || isEditing) && (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700" htmlFor="landAddress">Land Address</label>
            <input
              type="text"
              id="landAddress"
              value={landAddress}
              onChange={(e) => setLandAddress(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700" htmlFor="ownLandHectares">Own Land Hectares</label>
            <input
              type="number"
              id="ownLandHectares"
              value={ownLandHectares}
              onChange={(e) => setOwnLandHectares(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700" htmlFor="cultivatingLandHectares">Cultivating Land Hectares</label>
            <input
              type="number"
              id="cultivatingLandHectares"
              value={cultivatingLandHectares}
              onChange={(e) => setCultivatingLandHectares(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full bg-agro_green/90 text-white font-bold py-2 rounded-md hover:bg-agro_green/90"
          >
            {submittedData ? 'Update' : 'Submit'}
          </button>
        </form>
      )}

      {/* View Mode (when data is submitted and not editing) */}
      {submittedData && !isEditing && (
        <div className="space-y-12 py-[30px]">
          <div>
            <strong>Land Address:</strong> {submittedData.Location}
          </div>
          <div>
            <strong>Own Land Hectares:</strong> {submittedData.LandSize}
          </div>
          <div>
            <strong>Cultivating Land Hectares:</strong> {submittedData.CultivatedSize}
          </div>
          <button
            onClick={toggleEditMode}
            className="w-full bg-agro_green/90 text-white font-bold py-2 rounded-md hover:bg-agro_green"
          >
            Edit
          </button>
        </div>
      )}
    </div>
  );
}

export default LandForm;
