import React from "react"
import noprofile from '../Assests/sidebar/noprofile.png';
import { useSelector } from "react-redux";
import menu from '../Assests/sidebar/headerMenu.png';


function Header ({setShowSidebar,  showsidebar}){
   const username= useSelector((state) => state.signup.username);
   const headerContent  = useSelector((state) => state.headercontent.headerContent)

    return(
       <div className=" w-full bg-white flex flex-row  py-3 px-4  z-50 justify-between   shadow-md">
         <div className="flex flex-row gap-4 mob:gap-2">
            <button
               className={` ${showsidebar ? "mob2:hidden" : "sm:block lg:hidden"} flex items-center justify-center`}
               onClick={() => setShowSidebar(!showsidebar)}
            >
               <img src={menu} alt="menu icon" className="w-5 h-5" />
            </button>
            <h1 className="flex text-start font-semibold text-2xl mt-2 text-gray-500 tracking-wider  lg:ml-0">{headerContent}</h1>  
         </div>
  
          <div className="flex flex-row items-center gap-3 ">
                <img src={noprofile} className='w-12 h-12 object-cover border-2 rounded-full border-white' alt='profile'/>
                <h3 className='text-xs font-semibold text-gray-500 truncate mob2:hidden mob:block '>{username}</h3>
          </div>
       </div>

    );
}
export default Header;