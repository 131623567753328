import React, { useState } from 'react';
import { FaCopy } from "react-icons/fa";
import { hideSignupKey } from '../ReduxManagement/signupSlice';
import { useDispatch, useSelector } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';


function SignupKey() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [copyStatus, setCopyStatus] = useState(false);
  const username = useSelector((state) => state.signup.username);


  const handleKey = () => {
    navigate("/");
    dispatch(hideSignupKey());
  };


  const onCopyText = () => {
    setCopyStatus(true);
  };

  return (
    <div className='mt-20 mob:border-2 border-light_green rounded-lg flex p-3 py-8 sm:mx-12 mob:mx-8 md:mx-0'>
      <div className='flex flex-col gap-8 px-6 '>
        <h1 className='mob:text-[13px] mob2:text-[15px] text-black/50 text-center'>
          Please copy this username. Once it's closed, you won't be able to see the username again.
        </h1>
        
        <div className='relative flex flex-row items-center justify-center'>
          <input
            value={username}
            readOnly
            className='rounded-lg w-full border h-12 focus:outline-none mob2:text-[14px] px-4'
          />
          
          <CopyToClipboard text={username} onCopy={onCopyText}>
            <FaCopy 
              className="text-gray-400 absolute right-4 flex items-center justify-center cursor-pointer" 
            />
          </CopyToClipboard>
        </div>

        {copyStatus && (
          <p className="text-agro_green font-semibold text-center text-[12px] mt-2">
            Username copied to clipboard!
          </p>
        )}

        <h3 className='sm:text-[13px] mob2:text-[15px] tracking-wider text-center font-semibold'>
          Great! Now you can log in to <span className='text-agro_green text-[15px] font-bold tracking-normal'>Harvest Hub</span>
        </h3>

        <button
          className='w-full py-2 bg-agro_green text-white items-center justify-center'
          onClick={handleKey}
        >
          Login
        </button>
      </div>
    </div>
  );
}

export default SignupKey;
