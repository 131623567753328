// MapCard.jsx
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

const MapCard = ({ center, zoom }) => {
  return (
    <div className="lg:w-1/2 md:w-1/2 sm:w-full min-[320px]:w-full rounded overflow-hidden  bg-white shadow">
      <div className="p-4 ">
        <h2 className="text-xl font-bold mb-2">Map Location</h2>
        <div className="h-64">
          <MapContainer center={center} zoom={zoom} className="h-full">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={center}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default MapCard;
