// src/ShoppingCart.js
import React, { useState } from 'react';
import BottleImage from "../../Assests/FarmerDashboard/bottleImage.jpg"
import PaymentCard from './PaymentCard';
import { XMarkIcon } from '@heroicons/react/24/solid' // Import the X (cancel) icon from Heroicons
import deleteImg from '../../Assests/delete.png';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import CustomAxios from '../../API/CustomAxios';
import CreateNewItem from './Supplier/CreateNewItem';


const initialItems = [
    { id: 1, name: 'Product 1', price: 29.99, quantity: 0, stock: 10, image: BottleImage },
    { id: 2, name: 'Product 2', price: 19.99, quantity: 0, stock: 5, image: BottleImage },
    { id: 3, name: 'Product 3', price: 39.99, quantity: 0, stock: 8, image: BottleImage },
];


const ShoppingCart = () => {
    const API_BASE = process.env.REACT_APP_API_BASE;
    const [cartItems, setCartItems] = useState(initialItems);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showdDeleteModel, setshowdDeleteModel] = useState(false);
    const [selectedItem, setselectedItem] = useState("");
    const [showAddItemModel, setShowAddItemModel] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [userRole, setUserRole] = useState("supplier");


    // To calculate total price and total items
    const calculateTotal = () => {
        return cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
    };

    const calculateTotalItems = () => {
        return cartItems.reduce((total, item) => total + item.quantity, 0);
    };

    const handleIncreaseProduct = (id) => {
        setCartItems(cartItems.map(item =>
            item.id === id && item.quantity < item.stock
                ? {
                    ...item,
                    quantity: item.quantity + 1,
                    stock: item.stock - 1  
                }
                : item
        ));
    };

    const handleDecreaseProduct = (id) => {
        setCartItems(cartItems.map(item =>
            item.id === id && item.quantity > 0
                ? {
                    ...item,
                    quantity: item.quantity - 1,
                    stock: item.stock + 1 
                }
                : item
        ));
    };
    
    const handlePlaceOrder = () => {
        setIsModalOpen(true);
    };


    //   const handleConfirmOrder = () => {
    //     alert("Order confirmed!");
    //     setIsModalOpen(false); 
    //     setCartItems(initialItems); 
    //   };

    const handleCloseModal = () => {
        setIsModalOpen(false); // Close modal without confirming
    };

    const handleShowDeleteModel = (item) => {
        setshowdDeleteModel(true);
        setselectedItem(item);
    }

    const handleDeleteProduct = () => {
        setCartItems(cartItems.filter(item => item.id !== selectedItem.id));
        setshowdDeleteModel(false);
    }

    const handleAddItemModel = () => {
        setShowAddItemModel(true);
    }

    const handleCloseAddModel = () => {
        setShowAddItemModel(false);
    }

    const addItems = (newItem) => {
        setCartItems([...cartItems, newItem]);
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <div className="flex flex-row items-center mb-4 justify-between ">
                <h1 className="text-2xl font-bold  text-agro_green">Item List</h1>
                <button className={`${userRole === "supplier" ? "" : "hidden"} bg-agro_green text-[12px] text-white font-semibold px-3 py-2 rounded-md`} onClick={handleAddItemModel}>Add Items</button>
            </div>


            {/* Main Table for Products */}
            <div className="bg-white shadow-md rounded-lg overflow-hidden mb-4">
                <div className="overflow-x-auto min-[320px]:max-w-full">
                    <table className="min-w-full">
                        <thead>
                            <tr className="bg-light_green/50 text-xs">
                                <th className="py-2 px-4 text-left">Image</th>
                                <th className="py-2 px-4 text-left">Product</th>
                                <th className="py-2 px-4 text-left">Price</th>
                                <th className="py-2 px-4 text-left">Stock</th>
                                <th className="py-2 px-4 text-left">Quantity</th>
                                <th className="py-2 px-4 text-left">Total</th>
                                <th className="py-2 px-4 text-left">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cartItems.map(item => (
                                <tr key={item.id} className='text-xs'>
                                    <td className="py-2 px-4">
                                        <img src={item.image} alt={item.name} className="w-24 h-24 object-cover" />
                                    </td>
                                    <td className="py-2 px-4">{item.name}</td>
                                    <td className="py-2 px-4">${item.price.toFixed(2)}</td>
                                    <td className="py-2 px-4">{item.stock}</td>
                                    <td className="py-2 px-4 relative items-center justify-center space-x-1 lg:left-[5px] md:left-[5px]">
                                        <button
                                            onClick={() => handleDecreaseProduct(item.id)}
                                            className="bg-gray-300 rounded px-2 disabled:opacity-50"
                                            disabled={item.quantity === 0}>-</button>
                                        <span>{item.quantity}</span>
                                        <button
                                            onClick={() => handleIncreaseProduct(item.id)}
                                            className="bg-gray-300 rounded px-2 disabled:opacity-50"
                                            disabled={item.quantity === item.stock}>+</button>
                                    </td>
                                    <td className="py-2 px-4">${(item.price * item.quantity).toFixed(2)}</td>
                                    <td className='py-2 px-4 text-center'>
                                        <button className='w-5 h-5' onClick={() => handleShowDeleteModel(item)}><img src={deleteImg} /></button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>


            {/* Order Summary Table (Second Table) */}
            <div className="bg-white shadow-md rounded-lg overflow-hidden p-4 mb-4">
                <h2 className="text-xl font-semibold mb-4 text-agro_green">Order Summary</h2>
                <div className="overflow-x-auto">
                    <table className="min-w-full">
                        <thead>
                            <tr className="bg-light_green">
                                <th className="py-2 px-4 text-left">Product Name</th>
                                <th className="py-2 px-4 text-left">Quantity</th>
                                <th className="py-2 px-4 text-left">Price</th>
                                <th className="py-2 px-4 text-left">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cartItems.filter(item => item.quantity > 0).map(item => (
                                <tr key={item.id}>
                                    <td className="py-2 px-4">{item.name}</td>
                                    <td className="py-2 px-4">{item.quantity}</td>
                                    <td className="py-2 px-4">${item.price.toFixed(2)}</td>
                                    <td className="py-2 px-4">${(item.price * item.quantity).toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>


            {/* Total and Place Order Section */}
            {/* <div className="flex lg:flex-row md:flex-row sm:flex-col min-[320px]:flex-col gap-y-4 justify-between p-4 bg-light_green/30">
                <div>
                    <span className="font-bold">Total Items:</span>
                    <span>{calculateTotalItems()}</span>
                </div>
                <div>
                    <span className="font-bold">Total Price:</span>
                    <span>${calculateTotal()}</span>
                </div>
                <div className=''>
                    <span className="font-bold">Date:</span>
                    <span></span>
                </div>
            </div> */}


            {/* Place Order Button */}
            <div className="mt-4 text-center">
                <button
                    className="bg-agro_green text-white py-2 px-6 rounded-md hover:bg-agro_green/70 transition duration-300">
                    Add to Cart
                </button>
            </div>


            {/* Modal Component */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg max-w-sm w-full">
                        <div className='flex flex-row justify-between'>
                            <h2 className="text-xl font-semibold mb-4">Order Confirmation</h2>
                            <button
                                onClick={handleCloseModal}
                                className="flex items-center bg-red-500 text-white w-5 h-5 rounded-md hover:bg-red-600"
                            >
                                <XMarkIcon className="h-5 w-5" />  {/* Add the cancel (X) icon */}

                            </button>
                        </div>

                        <p className="mb-4 text-gray-400">Are you sure you want to place the order?</p>

                        <div className="">
                            {/* <button 
                onClick={handleConfirmOrder} 
                className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600">
                Confirm Order
              </button> */}
                            <PaymentCard></PaymentCard>
                        </div>
                    </div>
                </div>
            )}


            {/* show the delete model to delete an item */}
            {showdDeleteModel && (
                <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
                    <div className='bg-white p-6 rounded-lg shadow-lg w-96 flex flex-col items-center justify-center gap-4'>
                        <h1 className='text-sm text-center'>Are you sure you want to delete{' '}
                            <span className='font-semibold text-md'>{selectedItem.name}</span>?
                        </h1>
                        <div className='flex flex-row justify-between gap-12 text-white text-sm font-semibold'>
                            <button className='bg-agro_green py-2 rounded-md px-4' onClick={() => handleDeleteProduct()}>
                                Delete
                            </button>
                            <button
                                className='bg-red-500 py-2 rounded-md px-4'
                                onClick={() => setshowdDeleteModel(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}



            {/* show the add item model to add */}
            {(showAddItemModel &&
                <CreateNewItem closeModel={handleCloseAddModel} addItems={addItems} />
            )}

        </div>
    );
};

export default ShoppingCart;
