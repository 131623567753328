import React, {useState} from 'react';
import cover from '../../Assests/woman-studying-different-plants-with-tablet.jpg';
import CustomAxios from '../../API/CustomAxios';
import Alert from "../../Components/Alert";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';


function CreateSupplier() {
  const API_BASE = process.env.REACT_APP_API_BASE;
  const API_BASE_SMARTSCAP = process.env.REACT_APP_API_SMARTSCAP;
  const API_BASE_SMARTAJAP = process.env.REACT_APP_API_SMARTAJAP;
  const API_BASE_NORMALSCAP = process.env.REACT_APP_API_NORMALSCAP;
  const API_BASE_NORMALAJAP = process.env.REACT_APP_API_NORMALAJAP;
  const [alertProps, setAlertProps] = useState({message: "",severity: "success",show: false});


  //hide alert
  const hideAlert = () => {
    setAlertProps({ ...alertProps, show: false });
  };


  const validate=Yup.object({
     name: Yup.string()
     .min(3, "Must be more than 3 characters")
     .max(100, "Must be 100 characters or less")
     .required("*Required"),
     username: Yup.string()
     .min(8, "Must be more than 8 characters")
     .max(100, "Must be less than 100 characters")
     .matches( /^[a-z0-9@_]+$/,"*Username must include at least one number and can only contain lowercase letters, @, and _")
     .required("*Required"),
     email: Yup.string()
     .email('Invalid email address')
     .required('Email is required'),
     address: Yup.string()
     .required('Address is required'),
    //  nic:Yup.string()
    //  .required("*Required"),
     contactNo: Yup.string()
     .required('Contact Number is required'),
    //  password: Yup.string()
    //  .min(8, "Password must be at least 8 characters")
    //  .max(100, "Must be less than 100 characters")
    //  .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    //  .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    //  .matches(/[0-9]/, "Password must contain at least one number")
    //  .matches(/[@_]/, "Password must contain at least one symbol (@ or _)")
    //  .required("*Password is Required"),
     brandName: Yup.string()
     .required("*Required"),
    //  createdBy:Yup.string()
    //  .email('Invalid email address')
    //  .required('Email is required'),
    //  confirm_password: Yup.string()
    //  .oneOf([Yup.ref('password'), null], 'Passwords must match')
    //  .required('Confirm password is required'),        
  })


  //handle the creation of supplier
  const handleCreateSupplier = async(values)=>{
    const {confirm_password, ...newValues} = values;
    const suppliervalues = {Supplier:newValues};
    console.log(suppliervalues)
    try{
      const response = await CustomAxios({
        method:"POST",
        baseURL:API_BASE_NORMALSCAP,
        url:"supplier/signup",
        data: suppliervalues,
        headers:{
          "Content-Type":"application/json"
        },
      })
      if(response.status === 200){
        setAlertProps({severity: "success",message: "Supplier Created successfully",show: true});
      }else if(response.data.error){
        setAlertProps({severity: "error",message:response.data.error,show: true});
      }
    }catch(error){
      setAlertProps({severity: "error",message:"Error Occured",show: true});
      console.log(error);
    }
  }

  return (
  <div className="flex justify-center items-center w-full flex-col mt-2 relative h-full ">
    <div className='absolute inset-0 h-[85vh] w-full opacity-50'>
      <img src={cover} className=' object-cover h-full w-full'/>
    </div>

   {/* <h1 className='flex text-center justify-center font-semibold text-2xl text-gray-500 tracking-wider'>Create Supplier</h1> */}

{/* Form Content */}
  <Formik
    initialValues={{
    name: '',
    username:"",
    address:"",
    contactNo:"",
    // nic:"",
    brandName:"",
    email: '',
    // password: '',
    // confirm_password: '', 
    //  createdBy:"",
    role:"supplier",
    isVerified:true,
    status:"activate",
   }}
   
   validationSchema={validate}
   onSubmit={handleCreateSupplier}
  >
  {(formik) => (
<Form className="xl:mt-16 mob:my-4 mob:mx-2 md:mx-0 xl:h-auto mob2:h-[85vh]  mob:h-[80vh] sm:p-12  mob2:p-3 xl:w-auto  normal text-[14px] flex flex-col justify-center xl:items-center opacity-70 bg-black/70 z-10 overflow-y-scroll no-scrollbar ">
  <div className='grid h-full xl:grid-cols-2 xl:gap-12 mob2:gap-8 mob:gap-0 md:grid-cols-1  sm::mx-16 mob2:mx-8 lg:mx-8 xl:mx-0'>
    <div className='flex flex-col w-full mob2:gap-8 mob:gap-0 text-white font-semibold'>
      <div className='flex flex-col h-16 w-full'>
          <div className='flex mob:flex-row sm:gap-12 mob2:items-start mob:items-center mob:gap-2 mob2:flex-col'>
            <label className='sm:w-60 lg:w-48 xl:w-60 mob2:w-32 text-left'> Name</label>
                <div className='w-full flex flex-col'>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    className="border bg-agro_lightgreen/20 w-full mt-1 p-3 text-[12px] text-white/70 rounded-md focus:outline-none "
                  />
                  <ErrorMessage 
                    name="name" 
                    component="div" 
                    className="text-red-500 text-[9px] font-bold opacity-100 italic mt-1" 
                  />
                </div>
         </div>
        </div>


        <div className='flex flex-col h-16'>
          <div className='flex mob:flex-row sm:gap-12 mob2:items-start mob:items-center mob2:flex-col'>
            <label className='sm:w-60 lg:w-48 xl:w-60 mob2:w-32 text-left'> Username</label>
                <div className='w-full flex flex-col'>
                  <Field
                    type="text"
                    id="username"
                    name="username"
                    className="border bg-agro_lightgreen/20 w-full mt-1 p-3 text-[12px] text-white/70 rounded-md focus:outline-none "
                  />
                  <ErrorMessage 
                    name="username" 
                    component="div" 
                    className="text-red-500 text-[9px] font-bold italic mt-1" 
                  />
                </div>
         </div>
        </div>


        <div className='flex flex-col h-16'>
           <div className='flex mob:flex-row sm:gap-12 mob2:items-start mob:items-center mob2:flex-col'>
            <label className='sm:w-60 lg:w-48 xl:w-60 mob2:w-32 text-left'>Email</label>
            <div className='w-full flex flex-col'>
            <Field
                type="email"
                id="email"
                name="email"
                className="border w-full bg-agro_lightgreen/20  mt-1 p-3 text-[12px] text-white/70 rounded-md focus:outline-none "
            /> 
            <ErrorMessage 
                name="email" 
                component="div" 
                className="text-red-500 text-[9px] font-bold italic mt-1" 
            />
           </div>
         </div>
        </div>




  
    </div>      
        

<div className='flex flex-col mob2:gap-8 mob:gap-0 text-white'>
          {/* <div className='flex flex-col h-16'>
           <div className='flex mob:flex-row sm:gap-12 mob2:items-start mob:items-center mob2:flex-col'>
                <label className='sm:w-60 lg:w-48 xl:w-60 mob2:w-32 text-left'>UID</label>
                <div className='w-full flex flex-col'>
                  <Field
                    type="text"
                    id="nic"
                    name="nic"
                    className="border bg-agro_lightgreen/20  w-full mt-1 p-3 text-[12px] text-white/70 rounded-md focus:outline-none "
                  />
                  <ErrorMessage 
                   name="nic"
                   component="div" 
                   className="text-red-500 text-[9px] font-bold italic mt-1" 
                   />
                </div>
            </div>
        </div> */}


    <div className='flex flex-col h-16'>
           <div className='flex mob:flex-row sm:gap-12 mob2:items-start mob:items-center mob2:flex-col'>
                <label className='sm:w-60 lg:w-48 xl:w-60 mob2:w-32 text-left'>Address</label>
                <div className='w-full flex flex-col'>
                <Field
                    type="address"
                    id="address"
                    name="address"
                    className="border w-full bg-agro_lightgreen/20  mt-1 p-3 text-[12px] text-white/70 rounded-md focus:outline-none"
                />
                
                <ErrorMessage 
                   name="address" 
                   component="div" 
                   className="text-red-500 text-[9px] font-bold italic mt-1" 
                />
               </div>
           </div>
        </div>

      <div className='flex flex-col h-16'>
           <div className='flex mob:flex-row sm:gap-12 mob2:items-start mob:items-center mob2:flex-col'>
                <label className='sm:w-60 lg:w-48 xl:w-60 mob2:w-32 text-left'>Contact No.</label>
                <div className='w-full flex flex-col'>
                  <Field
                    type="text"
                    id="contactNo"
                    name="contactNo"
                    className="border w-full mt-1 bg-agro_lightgreen/20  p-3 text-[12px] text-white/70 rounded-md focus:outline-none "
                  />
                  <ErrorMessage 
                   name="contactNo"
                   component="div" 
                   className="text-red-500 text-[9px] font-bold italic mt-1" 
                   />
                </div>
            </div>
        </div> 

        <div className='flex flex-col h-16'>
           <div className='flex mob:flex-row sm:gap-12 mob2:items-start mob:items-center mob2:flex-col'>
                <label className='sm:w-60 lg:w-48 xl:w-60 mob2:w-32 text-left'>Brand Name</label>
                <div className='w-full flex flex-col'>
                  <Field
                    type="text"
                    id="brandName"
                    name="brandName"
                    className="border bg-agro_lightgreen/20  w-full mt-1 p-3 text-[12px] text-white/70 rounded-md focus:outline-none "
                  />
                  <ErrorMessage 
                   name="brandName"
                   component="div" 
                   className="text-red-500 text-[9px] font-bold italic mt-1" 
                   />
                </div>
            </div>
        </div>


        {/* <div className='flex flex-col h-16'>
           <div className='flex mob:flex-row sm:gap-12 mob2:items-start mob:items-center mob2:flex-col'>
                <label className='sm:w-60 lg:w-48 xl:w-60 mob2:w-32 text-left'>Admin email</label>
                <div className='w-full flex flex-col'>
                  <Field
                    type="email"
                    id="createdBy"
                    name="createdBy"
                    className="border bg-agro_lightgreen/20  w-full mt-1 p-3 text-[12px] text-white/70 rounded-md focus:outline-none "
                  />
                  <ErrorMessage 
                   name="createdBy"
                   component="div" 
                   className="text-red-500 text-[9px] font-bold italic mt-1" 
                   />
                </div>
            </div>
        </div> */}


        {/* <div className='flex flex-col h-16'>
           <div className='flex mob:flex-row sm:gap-12 mob2:items-start mob:items-center mob2:flex-col'>
                <label className='sm:w-60 lg:w-48 xl:w-60 mob2:w-32 text-left'>Password</label>
                <div className='w-full flex flex-col'>
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    className="border bg-agro_lightgreen/20  w-full mt-1 p-3 text-[12px] text-white/70 rounded-md focus:outline-none "
                  />
                  <ErrorMessage 
                   name="password"
                   component="div" 
                   className="text-red-500 text-[9px] font-bold italic mt-1" 
                   />
                </div>
            </div>
        </div>

                
        <div className='flex flex-col h-16'>
           <div className='flex mob:flex-row sm:gap-12 mob2:items-start mob:items-center mob2:flex-col'>
                <label className='sm:w-60 lg:w-48 xl:w-60 mob2:w-32 text-left'>Confirm Password</label>
                <div className='w-full flex flex-col'>
                <Field
                    type="password"
                    id="confirm_password"
                    name="confirm_password"
                    className="border bg-agro_lightgreen/20  w-full mt-1 p-3 text-[12px] text-white/70 rounded-md focus:outline-none "
                />
                
                <ErrorMessage 
                   name="confirm_password" 
                   component="div" 
                   className="text-red-500 text-[9px] font-bold italic mt-1" 
                />
                </div>
            </div>
        </div> */}
    </div>

    <div className="flex justify-center">
      <button type="submit" className="p-2 my-4 bg-agro_darkgreen  z-10 opacity-100 w-64 flex mob2:justify-center text-white rounded-lg text-[13px] focus:outline-none">
          Create Supplier
      </button>
    </div>
  </div>      
     </Form>
    )}
 </Formik>

  {/* Calling the Alert component */}
  <Alert
    message={alertProps.message}
    severity={alertProps.severity}
    show={alertProps.show}
    onClose={hideAlert}
  />

</div>
  );
}

export default CreateSupplier;
