import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { useState } from 'react';
import CustomAxios from '../../../API/CustomAxios';
import { useSelector } from 'react-redux';


function AddNewProduct({ closeModel, refreshProducts }) {
    const [imageUrl, setImageUrl] = useState(null);
    const API_BASE = process.env.REACT_APP_API_BASE;
    const API_BASE_SMARTSCAP = process.env.REACT_APP_API_SMARTSCAP;
    const API_BASE_SMARTAJAP = process.env.REACT_APP_API_SMARTAJAP;
    const API_BASE_NORMALSCAP = process.env.REACT_APP_API_NORMALSCAP;
    const API_BASE_NORMALAJAP = process.env.REACT_APP_API_NORMALAJAP;
    const [userid,setuserid] = useState(useSelector((state) => state.auth.userID));

  
    const handleAddProduct = async (values) => {
      console.log("Product added:", values);
      try {
        const newproductResponse = await CustomAxios({
          method: "POST",
          baseURL: API_BASE_SMARTAJAP,
          url: "CreateProduct",
          data: values,
          headers: {
            "Content-Type": "application/json"
          },
        });
        if(newproductResponse.status === 200){
          console.log("new product response", newproductResponse.data);
          refreshProducts();
          closeModel();
        }
      } catch (error) {
        console.log(error);
      }
      
    };
  
    const validate = Yup.object({
      productID: Yup.string().required("*Required"),
      productName: Yup.string().required("*Required"),
      productType: Yup.string().required("*Required"),
      productPrice: Yup.string().required("*Required"),
      productStock:Yup.string().required("*Required"),
      productVolume:Yup.string().required("*Required"),
      // image: Yup.mixed().required("*Required"),
    });
  
    
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-6 sm:rounded-lg shadow-lg sm:w-[600px] mob2:w-full mob2:h-full  sm:h-[90vh] flex flex-col gap-4 relative overflow-y-auto no-scrollbar">
          <h1 className="text-center text-agro_darkgreen font-semibold text-lg">Add Product</h1>
          <Formik
            initialValues={{
              supplierID: userid,
              productID:"",
              productName: "",
              productType: "",
              productPrice:"",
              // image: "",
              productStock: "",
              productVolume:"",
            }}
            validationSchema={validate}
            onSubmit={handleAddProduct}
          >
            {(formik) => (
              <Form className="flex flex-col gap-3">
                {/* Field for Product Name */}
                <div className="flex flex-row items-center gap-3 h-14 w-full">
                  <label className="text-[13px] text-black/70 w-32">Product Name</label>
                  <div className="flex flex-col w-full">
                    <Field
                      type="text"
                      id="productName"
                      name="productName"
                      className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                    />
                    <ErrorMessage
                      name="productName"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
  

                {/* Field for Product Type */}
                <div className="flex flex-row items-center gap-3 w-full h-14">
                  <label className="text-[13px] text-black/70 w-32">Product Type</label>
                  <div className="flex flex-col w-full">
                    <Field
                      as="select"
                      id="productType"
                      name="productType"
                      className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                    >
                      <option value="">Select Type</option>
                      <option value="fertilizer">Fertilizer</option>
                      <option value="pesticide">Pesticide</option>
                      <option value="seeds">Seeds</option>
                    </Field>
                    <ErrorMessage
                      name="productType"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>


              {/* Field for Product ID */}
              <div className="flex flex-row items-center gap-3 w-full h-14">
                  <label className="text-[13px] text-black/70 w-32">Product ID</label>
                  <div className="flex flex-col w-full">
                    <Field
                        type="text"
                        id="productID"
                        name="productID"
                        className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                      />
                      <ErrorMessage
                        name="productID"
                        component="div"
                        className="text-red-500 text-[9px] font-medium italic mt-1"
                      />
                  </div>
              </div>


              {/* Field for Product Volume */}
              <div className="flex flex-row items-center gap-3 w-full h-14">
                  <label className="text-[13px] text-black/70 w-32">Volume</label>
                  <div className="flex flex-col w-full">
                    <Field
                        type="text"
                        id="productVolume"
                        name="productVolume"
                        className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                      />
                      <ErrorMessage
                        name="productVolume"
                        component="div"
                        className="text-red-500 text-[9px] font-medium italic mt-1"
                      />
                  </div>
              </div>



                {/* Field for Image */}
                <div className="flex flex-row items-center gap-3 w-full h-14">
                  <label className="text-[13px] text-black/70 w-32">Image</label>
                  <div className="flex flex-col w-full">
                    <input
                      type="file"
                      id="image"
                      name="image"
                      onChange={(event) => {
                        formik.setFieldValue("image", event.currentTarget.files[0]);
                        const reader = new FileReader();
                        reader.onload = () => {
                          setImageUrl(reader.result);
                        };
                        reader.readAsDataURL(event.currentTarget.files[0]);
                      }}
                      className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                    />
                    <ErrorMessage
                      name="image"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
  
                {/* Image Preview */}
                <div className="flex flex-row items-center gap-3 w-full">
                  <label className="text-[13px] text-black/70 w-32"></label>
                  <div className="flex flex-col w-full">
                    {imageUrl && (
                      <img
                        src={imageUrl}
                        alt="Preview"
                        className="mt-2 w-auto object-cover rounded-lg"
                      />
                    )}
                  </div>
                </div>
  

                {/* Field for Product price */}
                <div className="flex flex-row items-center gap-3 w-full h-14">
                  <label className="text-[13px] text-black/70 w-32">Price</label>
                  <div className="flex flex-col w-full">
                    <Field
                      type="text"
                      id="productPrice"
                      name="productPrice"
                      className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                    />
                    <ErrorMessage
                      name="productPrice"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>


              {/* Field for Product stock */}
              <div className="flex flex-row items-center gap-3 w-full h-14">
                  <label className="text-[13px] text-black/70 w-32">Stock</label>
                  <div className="flex flex-col w-full">
                    <Field
                        type="text"
                        id="productStock"
                        name="productStock"
                        className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                      />
                      <ErrorMessage
                        name="productStock"
                        component="div"
                        className="text-red-500 text-[9px] font-medium italic mt-1"
                      />
                  </div>
              </div>
  


  
                <button type="submit" className="mt-4 bg-agro_darkgreen text-white p-2 rounded-lg">
                  Add Product
                </button>
  
                {/* Close Button */}
                  <button
                    className="absolute top-0 right-0 mt-2 mr-2 bg-white text-red-500 font-bold rounded-full w-8 h-8"
                    onClick={closeModel}
                  >
                    X
                  </button>

              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
  
export default AddNewProduct