import React, { useState, useEffect } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from 'react-router-dom';
import CustomAxios from '../../../API/CustomAxios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import seedimg from '../../../Assests/FarmerDashboard/seeds.jpg';
import edit from "../../../Assests/edit.png";
import deleteimg from '../../../Assests/delete.png';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';


function ManageStock() {
    const brand = useSelector((state) => state.auth.brandname);
    const API_BASE = process.env.REACT_APP_API_BASE;
    const API_BASE_SMARTSCAP = process.env.REACT_APP_API_SMARTSCAP;
    const API_BASE_SMARTAJAP = process.env.REACT_APP_API_SMARTAJAP;
    const API_BASE_NORMALSCAP = process.env.REACT_APP_API_NORMALSCAP;
    const API_BASE_NORMALAJAP = process.env.REACT_APP_API_NORMALAJAP;

    const navigate =useNavigate();
    const [searchTerm, setSearchTerm]= useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [displayedOrders, setDisplayedOrders] = useState([]);
    const [editModel, setEditModel] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");
    const [userid,setuserid] = useState(useSelector((state) => state.auth.userID));

    const validate=Yup.object({
        productID: Yup.string().required("*Required"),
        productName: Yup.string().required("*Required"),
        productType: Yup.string().required("*Required"),
        productPrice: Yup.string().required("*Required"),
        productStock:Yup.string().required("*Required"),
        productVolume:Yup.string().required("*Required"),
        // image: Yup.mixed().required("*Required"),
    })



    // Set initial orders display to show all orders
    useEffect(() => {
        getStockManagementData();
    }, []);


  //get the data of the items to stock management
    const getStockManagementData = async()=>{
        try{
            const response = await CustomAxios({
                method:"GET",
                baseURL:API_BASE_SMARTAJAP,
                url:`GetAllProductBySelectedSupplierBrandName`,
                params:{
                    brandName:brand
                },
                headers:{
                  "Content-Type":"application/json"
                },
            })
            if(response.status === 200){
                setDisplayedOrders(response.data.products);
            }
        }catch(error){
            console.log(error);
        }
    }


    //handle the edit model
    const handleEditModel =(item)=>{
        setEditModel(true);
        setSelectedItem(item);
        console.log(item);
    }

    
    //handle the update of stocks
    const handleStockUpdate = async(values)=>{
        try{
            const response = await CustomAxios({
                method:"PUT",
                baseURL:API_BASE_SMARTAJAP,
                url:"UpdateProduct",
                params:{
                    productID:selectedItem.productID
                },
                data:values,
                headers:{
                  "Content-Type":"application/json"
                },
            })
            if(response.status === 200){
                const updatedStock = displayedOrders.map((item)=> item.productID === selectedItem.productID ? {...item, ...values} : item);
                setDisplayedOrders(updatedStock);
                setEditModel(false);        
            }
        }catch(error){
            console.log(error);
        }
    }

    const handleDeleteModel = (item)=>{
        setDeleteModel(true);
        setSelectedItem(item);
    }


    const handleStockDelete = async()=>{
        try{
            const response = await CustomAxios({
                method:"DELETE",
                baseURL:API_BASE_SMARTAJAP,
                url:"DeleteProduct",
                params:{
                    productID:selectedItem.productID
                },
                headers:{
                  "Content-Type":"application/json"
                },
            })
            if(response.status === 200){
                const updatedStock = displayedOrders.filter((item) => item !== selectedItem.productID)
                setDisplayedOrders(updatedStock);
                setDeleteModel(false);
            }
        }catch(error){
            console.log(error);
        }
    }


    //handle the page
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    //handle the rows for a page
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    // Filter items when the search button is clicked
    const handleSearch = () => {
        const filtered = displayedOrders.filter(order =>
            order.order_Id.includes(searchTerm)
        );
        setDisplayedOrders(filtered);
        setPage(0);
    };

  return (
    <>
    {/* <div className='flex flex-col items-center justify-center mt-2'>
        <h1 className='flex text-center justify-center font-semibold text-2xl text-gray-500 tracking-wider'> Stock Management</h1>
    </div> */}

    {/* The Table starts here */}
    <div className='flex flex-col rounded-lg mt-6 '>
    <div className='flex sm:flex-row w-full justify-between  mob2:flex-col '>
        {/* <h1 className='font-semibold text-xl text-black/50'>Stocks</h1> */}
        <div className='flex flex-row gap-3 mob2:justify-center mob:justify-end mob2:w-full mob:w-auto mob2:mt-2 sm:mt-0'>
            <input
                className='rounded-md p-3 mob:w-48 mob2:w-full text-xs bg-white focus:outline-none'
                placeholder="Search by Item Id"
                value={searchTerm}
                onChange={(e)=> setSearchTerm(e.target.value)}
            />
            <button
                className='px-6 rounded-md text-white bg-agro_darkgreen text-sm font-semibold'
                onClick={handleSearch}
            >
                Search
            </button>
        </div>
    </div>

    <div className='flex flex-col mt-2'>
        <TablePagination 
            component="div"
            rowsPerPageOptions={[2,10,25,50]}
            page={page}
            count={displayedOrders.length}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
                '@media (max-width: 640px)': {
                    '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                        fontSize: '10px', 
                    },
                    fontSize: '10px',   
                },
                fontSize: '14px', 
            }}
        />
        <div className='overflow-x-auto rounded-lg bg-white'>
            <table className='min-w-full rounded-lg'>
                <thead className='bg-agro_lightgreen/60 text-black/70 text-left'>
                    <tr>
                        <th className='px-6 py-3 text-xs'>No</th>
                        <th className='px-6 py-3 text-xs'>Product Image</th>
                        <th className='px-6 py-3 text-xs'>Product ID</th>
                        <th className='px-6 py-3 text-xs'>Product Name</th>
                        <th className='px-6 py-3 text-xs'>Volume</th>
                        <th className='px-6 py-3 text-xs'>Stock</th>
                        <th className='px-6 py-3 text-xs'>Price</th>
                        <th className='px-6 py-3 text-xs'>Update</th>
                        <th className='px-6 py-3 text-xs'>Delete</th>
                    </tr>
                </thead>

                <tbody>
                    {displayedOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                       item.productDetails.map((detail, detailIndex) => (
                        <tr key={item.productID} className=' text-xs font-normal border border-b-agro_green/20'>
                            <td className='px-6 py-3'>{page * rowsPerPage + index + 1}</td>
                            <td className='px-6 py-3'>
                                <img src={item.productDetails?.image || seedimg}   className='w-8 h-8'/>
                            </td>
                            <td className='px-6 py-3'>{item.productID}</td>
                            <td className='px-6 py-3'>{item.productName}</td>
                            <td className='px-6 py-3'>{detail.productVolume}</td>
                            <td className='px-6 py-3'>{detail.productStock}</td>
                            <td className='px-6 py-3'>{detail.productPrice}</td>
                            <td className='px-6 py-3'>
                                <button 
                                    className='rounded-md text-black/60 font-semibold '
                                    onClick={()=>handleEditModel(item)}
                                >
                                   <img src={edit} className='w-5 h-5'/>
                                </button>
                            </td>
                            <td className='px-6 py-3'>
                                <button 
                                    className=' rounded-md text-black/60 font-semibold '
                                    onClick={()=>handleDeleteModel(item)}
                                >
                                   <img src={deleteimg} className='w-5 h-5' />
                                </button>
                            </td>
                        </tr>
                       )
                    )
                    ))}
                </tbody>
            </table>
        </div>
    </div>
    </div>


{/* when the update is clicked */}
{editModel && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-6 sm:rounded-lg shadow-lg max-w-[550px] flex flex-col gap-4 mob2:w-full overflow-y-auto no-scrollbar">
            <h1 className="text-agro_green font-semibold tracking-wide text-center sm:text-lg mob2:my-6 sm:my-0 mob2:text-2xl">
                Updating {selectedItem.productName}
            </h1>
            <Formik
                initialValues={{
                    supplierID: userid,
                    productID:selectedItem.productID,
                    productName: selectedItem.productName,
                    productType: selectedItem.productType,
                    productPrice:selectedItem.productPrice,
                    // image: "",
                    productStock: selectedItem.productStock,
                    productVolume:selectedItem.productVolume,
                }}
                validationSchema={validate}
                onSubmit={handleStockUpdate}
            >
            {(formik) => (
                <Form className="flex flex-col sm:gap-5 mob2:gap-8">
                    {/* Field for product name */}
                    <div className="flex w-full items-center">
                        <label className="text-[13px] text-black w-1/4">Product Name</label>
                        <div className="relative w-3/4">
                            <Field
                                type="text"
                                id="productName"
                                name="productName"
                                onChange={formik.handleChange}
                                value={formik.values.productName}
                                className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                            />
                            <ErrorMessage
                                name="productName"
                                component="div"
                                className="absolute left-0 top-full text-red-500 text-[9px] font-medium italic mt-1"
                            />
                        </div>
                    </div>


                    {/* Field for product Type */}
                    <div className="flex w-full items-center">
                        <label className="text-[13px] text-black w-1/4">Product Type</label>
                        <div className="relative w-3/4">
                            <Field
                                as="select"
                                id="productType"
                                name="productType"
                                onChange={formik.handleChange}
                                value={formik.values.productType}
                                className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                            >
                               <option value={selectedItem.productType} disabled>{selectedItem.productType || "Select Type"}</option>
                                <option value="fertilizer">Fertilizer</option>
                                <option value="pesticide">Pesticide</option>
                                <option value="seeds">Seeds</option>
                            </Field>
                            <ErrorMessage
                                name="productType"
                                component="div"
                                className="absolute left-0 top-full text-red-500 text-[9px] font-medium italic mt-1"
                            />
                        </div>
                    </div>


                    {/* Field for product ID */}
                    <div className="flex w-full items-center">
                        <label className="text-[13px] text-black w-1/4">Product ID</label>
                        <div className="relative w-3/4">
                            <Field
                                type="text"
                                id="productID"
                                name="productID"
                                onChange={formik.handleChange}
                                value={formik.values.productID}
                                className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                            />
                            <ErrorMessage
                                name="productID"
                                component="div"
                                className="absolute left-0 top-full text-red-500 text-[9px] font-medium italic mt-1"
                            />
                        </div>
                    </div>

                    
                    {/* Field for product volume */}
                    <div className="flex w-full items-center">
                        <label className="text-[13px] text-black w-1/4">Volume</label>
                        <div className="relative w-3/4">
                            <Field
                                type="text"
                                id="productVolume"
                                name="productVolume"
                                onChange={formik.handleChange}
                                value={formik.values.productVolume}
                                className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                            />
                            <ErrorMessage
                                name="productVolume"
                                component="div"
                                className="absolute left-0 top-full text-red-500 text-[9px] font-medium italic mt-1"
                            />
                        </div>
                    </div>


                    {/* Field for product price */}
                    <div className="flex w-full items-center">
                        <label className="text-[13px] text-black w-1/4">Price</label>
                        <div className="relative w-3/4">
                            <Field
                                type="text"
                                id="productPrice"
                                name="productPrice"
                                onChange={formik.handleChange}
                                value={formik.values.productPrice}
                                className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                            />
                            <ErrorMessage
                                name="productPrice"
                                component="div"
                                className="absolute left-0 top-full text-red-500 text-[9px] font-medium italic mt-1"
                            />
                        </div>
                    </div>


                    {/* Field for product price */}
                    <div className="flex w-full items-center">
                        <label className="text-[13px] text-black w-1/4">Stock</label>
                        <div className="relative w-3/4">
                            <Field
                                type="text"
                                id="productStock"
                                name="productStock"
                                onChange={formik.handleChange}
                                value={formik.values.productStock}
                                className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                            />
                            <ErrorMessage
                                name="productStock"
                                component="div"
                                className="absolute left-0 top-full text-red-500 text-[9px] font-medium italic mt-1"
                            />
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex flex-row w-full justify-evenly text-sm text-white font-semibold gap-12">
                            <button
                                className="sm:py-2 px-8 w-full bg-agro_greenlight rounded-md mob2:py-3"
                                type="submit"
                            >
                                Update
                            </button>
                            <button
                                className="sm:py-2 px-8 w-full bg-red-500 rounded-md mob2:py-3"
                                onClick={() => setEditModel(false)}
                            >
                                Cancel
                            </button>
                    </div>
                </Form>
            )}
        </Formik>
        </div>
    </div>
)}

{/* show the delete model */}
{deleteModel &&(
       <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
       <div className='bg-white p-6 rounded-lg shadow-lg w-96 flex flex-col items-center justify-center gap-4'>
         <h1 className='text-sm text-center'>
           Are you sure you want to delete{' '}
           <span className='font-semibold text-md'>{selectedItem.productName}</span>?
         </h1>
         <div className='flex flex-row justify-between gap-12 text-white text-sm font-semibold'>
           <button className='bg-agro_green py-2 rounded-md px-4' onClick={() => handleStockDelete()}>
             Delete
           </button>
           <button
             className='bg-red-500 py-2 rounded-md px-4'
             onClick={() => setDeleteModel(false)}
           >
             Cancel
           </button>
         </div>
       </div>
     </div>
)}
    </>
  )
}

export default ManageStock