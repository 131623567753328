import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    headerContent:"Overview",
};

export const headerContentSlice =  createSlice({
    name: "headercontent",
    initialState,
    reducers:{
        setHeaderContent: (state, action) =>{
            state.headerContent = action.payload;
        },
        clearHeaderContent: (state) => {
            state.headerContent = initialState.headerContent; 
        },
    }
});

export const { setHeaderContent, clearHeaderContent} = headerContentSlice.actions;
export default headerContentSlice.reducer;