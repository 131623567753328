import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userRole: null,
    userID: null,
    REFRESHTOKEN:null,
    storeAddress:null,
    brandname:null,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUserRole: (state, action) => {
            state.userRole = action.payload;
        },
        setUserID: (state, action) =>{
            state.userID = action.payload;
        },
        setToken:(state, action)=>{
            state.REFRESHTOKEN = action.payload;
        },
        setAddress:(state, action)=>{
            state.storeAddress = action.payload;
        },
        SetBrandName:(state,action)=>{
            state.brandname = action.payload;
        },
        clearAuth:(state)=>{
            state.userID = initialState.userID;
            state.userRole = initialState.userRole;
        },
    },
});

export const { setUserRole, setUserID, clearAuth, setAddress, setToken,SetBrandName } = authSlice.actions;
export default authSlice.reducer;
