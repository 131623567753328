import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling, faShower, faLeaf } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux'; // Import useSelector from react-redux

const iconMap = {
  "seeds": { icon: faSeedling, color: 'text-agro_green' },
  "fertilizer": { icon: faLeaf, color: 'text-light_green' },
  "pesticide": { icon: faShower, color: 'text-agro_yellow' },
};

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  // Get the farmerID from the Redux store
  const farmerID = useSelector(state => state.farmerID);  // Adjust based on your actual Redux state structure

  // Fetch data from the API
  useEffect(() => {
    if (!farmerID) {
      console.error("Farmer ID is missing from the Redux store.");
      setLoading(false);
      return;
    }

    const fetchOrders = async () => {
      try {
        // Use fetch instead of axios
        const response = await fetch(`https://scapione.bethel.network/getOrdersByFarmer?farmerID=${farmerID}`);

        // Check if the response is okay (status code 200-299)
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const orderData = data.orders;

        // Extract and flatten order items
        const flattenedOrders = orderData.flatMap(order =>
          order.OrderItems.flatMap(item =>
            item.itemDetails.map(itemDetail => ({
              OrderID: order.OrderID,
              productName: item.productName,
              itemType: itemDetail.itemType,
              quantity: itemDetail.quantity,
              unitPrice: itemDetail.unitPrice,
              supplierName: item.supplierID, // Supplier name
              orderDate: order.OrderStartDate, // Using OrderStartDate for date grouping
              totalPrice: order.totalPrice,
            }))
          )
        );

        setOrders(flattenedOrders);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setLoading(false);
      }
    };

    fetchOrders();
  }, [farmerID]); // The effect depends on farmerID

  // Group orders by month
  const groupedOrders = orders.reduce((acc, order) => {
    const orderDate = new Date(order.orderDate);
    const month = orderDate.toLocaleString('default', { month: 'long' }) + ' ' + orderDate.getFullYear();
    (acc[month] = acc[month] || []).push(order);
    return acc;
  }, {});

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen flex flex-col items-center p-6 bg-gray-100">
      <h1 className="text-3xl font-semiBold mb-6 text-agro_darkgreen">View Your Past Orders</h1>
      <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg p-6">
        {Object.entries(groupedOrders).map(([month, orders]) => (
          <div key={month} className="mb-8">
            <h2 className="text-2xl font-semibold text-agro_gree mb-2">{month}</h2>
            <ul className="mt-2">
              {orders.map(order => (
                <li key={order.OrderID} className={`flex lg:flex-row md:flex-row sm:flex-col min-[320px]:flex-col items-center justify-between p-4 border-b ${iconMap[order.itemType]?.color || ''}`}>
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={iconMap[order.itemType]?.icon || faLeaf} className="mr-3" />
                    <span className="font-medium">{order.productName} ({order.itemType})</span>
                  </div>
                  <span className="text-gray-600">{order.orderDate} ({order.quantity} x {order.unitPrice} each)</span>
                  <span className="text-gray-600">Supplier: {order.supplierName}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div> 
  );
};

export default OrderHistory;
