// Alerts.js
import React, { useState } from 'react';
import { Snackbar, Alert } from '@mui/material';

const Alerts = ({ message, severity, show, onClose }) => (
  <Snackbar 
    open={show} 
    autoHideDuration={3000} 
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    onClose={onClose}
  >
    <Alert elevation={6} variant="filled" severity={severity}>
      {message}
    </Alert>
  </Snackbar>
);

export default Alerts;

