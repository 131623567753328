import React from 'react'


function PlacedOrderDetails({orderAmount,status}) {
    console.log("ppp", orderAmount);
   

  return (
    <div className='bg-white shadow-md rounded-md p-4 flex flex-col gap-4 '>
        <h1>ORDER DETAILS</h1>
        <div className='w-full text-[12px] text-black/50 flex flex-col gap-5'>
            <div className=' grid grid-cols-3 '>
                <label className='font-semibold col-span-1'>Total Amount</label>
                <span className='w-full font-bold'>{orderAmount}</span>
            </div>

            <div className=' grid grid-cols-3 '>
                <label className='font-semibold col-span-1'>Status</label>
                <span className='w-full font-bold'>
                    {status === "Accepted"? (
                        <div className='bg-agro_darkyellow px-2 py-1 rounded-lg w-24'>
                            Accepted
                        </div>
                    ):(
                        <div className='bg-agro_lightgreen px-2 py-1 rounded-lg w-24'>
                            Pending
                        </div>
                    )}
                </span>
            </div>

            {/* <button className=' bg-light_green py-2 text-black font-semibold text-[13px] w-full rounded-md'>Complete Order</button> */}
        </div>
    </div>
  )
}

export default PlacedOrderDetails