// Products.js
import React, { useEffect, useState } from "react";
import Fertilizer from "../../Assests/FarmerDashboard/bottleImage.jpg";
import Pesticides from "../../Assests/FarmerDashboard/pesticides.jpg";
import Seeds from "../../Assests/FarmerDashboard/seeds.jpg";
import { FaCartPlus } from "react-icons/fa"; 
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../ReduxManagement/cartSlice";
import CustomAxios from "../../API/CustomAxios";
import AddNewProduct from "./Supplier/AddNewProduct";
import { useNavigate } from "react-router-dom";


const Products = () => {
  const [userRole, setUserRole] = useState(useSelector((state) => state.auth.userRole));
  const navigate = useNavigate();
  // const userRole = "supplier"
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState();
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [allProducts, setAllProducts] = useState([]);
  // const [showAddModel, setShowAddModel] = useState(false);
  const [allTypes, setAllTypes] = useState([]);
  const [allCategory, setAllcategory] = useState([]);
  const API_BASE_SMARTAJAP = process.env.REACT_APP_API_SMARTAJAP;


  useEffect(() => {
    if (selectedType && selectedSupplier) {
      fetchProductsByTypeAndSupplier(selectedType, selectedSupplier);
    } else if (selectedType) {
      fetchProductsByType(selectedType);
    } else if (selectedSupplier) {
      fetchProductsBySupplier(selectedSupplier);
    } else {
      getAllProducts();
    }
  }, [selectedType, selectedSupplier]);

  useEffect(()=>{
    getProductTypes()
    getProductCateogary();
  },[])


  const getProductTypes = async()=>{
    try{
      const response = await CustomAxios({
        method: "GET",
        baseURL: API_BASE_SMARTAJAP,
        url: "GetAllSupplierBrandNames",
        headers: {
          "Content-Type": "application/json",
        },
      })
      if(response.status===200){
        setAllTypes(response.data.productTypes);
      }
    }catch(error){

    }
  }

  const getProductCateogary=async()=>{
    try{
      const response = await CustomAxios({
        method: "GET",
        baseURL: API_BASE_SMARTAJAP,
        url: "GetAllProductTypes",
        headers: {
          "Content-Type": "application/json",
        },
      })
      if(response.status===200){
        setAllcategory(response.data.brandNames);
      }
    }catch(error){

    }
  }

  const handleAddToCart = (product) => {
    const productData = {
      id: product.productID,
      brand: product.productName,
      type: product.productType,
      volume: product.productVolume,
      price: product.productPrice,
      supplierID:product.supplierID,
      quantity: 1
    };
    dispatch(addToCart(productData));  // Dispatch product data with quantity 1
  };

  //get all products
  const getAllProducts = async () => {
    try {
      const response = await CustomAxios({
        method: "GET",
        baseURL: API_BASE_SMARTAJAP,
        url: "GetAllProducts",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        console.log(response.data);
        console.log(response.data.products);
        setAllProducts(response.data);
      }
    } catch (error) {
      console.error("Error fetching all products:", error);
    }
  };


  const fetchProductsByType = async (type) => {
    try {
      const response = await CustomAxios({
        method: "GET",
        baseURL: API_BASE_SMARTAJAP,
        url: `GetProductsByType?type=${type}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        setAllProducts(response.data);
      }
    } catch (error) {
      console.error("Error fetching products by type:", error);
    }
  };

  const fetchProductsBySupplier = async (supplier) => {
    try {
      const response = await CustomAxios({
        method: "GET",
        baseURL: API_BASE_SMARTAJAP,
        url: `GetProductsBySupplier?supplier=${supplier}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        setAllProducts(response.data);
      }
    } catch (error) {
      console.error("Error fetching products by supplier:", error);
    }
  };

  const fetchProductsByTypeAndSupplier = async (type, supplier) => {
    try {
      const response = await CustomAxios({
        method: "GET",
        baseURL: API_BASE_SMARTAJAP,
        url: `GetProductNamesByTypeAndSupplier`,
        params: {
          productType: type,
          productBrand: supplier,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        setAllProducts(response.data.products);
      }
    } catch (error) {
      console.error("Error fetching products by type and supplier:", error);
    }
  };

  const handleType = (e) => {
    setSelectedType(e.target.value);
  };

//handle the selected supplier
  const handleSupplier = (e) => {
    setSelectedSupplier(e.target.value);
  };

  // const handleAddProductModel = () => {
  //   setShowAddModel(true);
  // };

  // const handleCloseAddModel = () => {
  //   setShowAddModel(false);
  // };



  return (
    <>
      <header className="mt-6 rounded-t-md text-black text-sm relative">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <div className="flex flex-row gap-3">
            <select
              value={selectedType}
              onChange={handleType}
              className="p-2 rounded bg-white text-gray-800"
            >
              <option value="">Sort By Type</option>
              <option value="fertilizers">Fertilizers</option>
              <option value="pesticides">Pesticides</option>
              <option value="seeds">Seeds</option>
            </select>
            <select
              value={selectedSupplier}
              onChange={handleSupplier}
              className="p-2 rounded bg-white text-gray-800"
            >
              <option value="">Sort By Supplier</option>
              <option value="supplier-one">Supplier One</option>
              <option value="supplier-two">Supplier Two</option>
              <option value="supplier-three">Supplier Three</option>
            </select>

            <button 
              className={`${userRole === "farmer" ? "hidden" : ""} bg-agro_darkgreen p-2 w-32 rounded-md text-white`}
              // onClick={handleAddProductModel}
              onClick={()=>navigate("/dashboard/createProduct")}
            >
              Add Product
            </button>
          </div>
        </div>
      </header>

      <main className="mt-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {allProducts.map((supplier) => (supplier.products.length >0  && supplier.products.map((product) =>(product.productDetails.map((detail) => (
                <div key={detail.productID} className="bg-white p-4 rounded-lg shadow-md relative">
                <button
                  onClick={() => handleAddToCart(product)}
                  className={`${userRole == "supplier" ? "hidden" : "absolute"} bottom-4 right-4 bg-green-600 text-white p-2 rounded-full shadow-lg hover:bg-green-700`}
                >
                  <FaCartPlus className="text-lg" />
                </button>
                <img src={product.imageUrl || Seeds} onError={(e) => {e.target.src = Seeds}} alt={product.productName} className="w-full h-32 object-cover rounded mb-4" />
  
                <h3 className="text-xl font-semibold mb-2">{product.productName}</h3>
                <p className="text-gray-600">Type: {product.productType}</p>
                <p className="text-gray-600">Volume: {detail.productVolume}</p>
                <p className="text-gray-800 font-semibold">Price: {detail.productPrice}</p>
                <p className="text-gray-800 font-semibold hidden">SupplierID: {product.supplierID}</p>
              </div>
              ))
            ))

          ))}
        </div>
      </main>

      {/* {showAddModel && (
          <AddNewProduct closeModel={handleCloseAddModel} refreshProducts={getAllProducts} />
        )} */}
    </>
  );
};

export default Products;
