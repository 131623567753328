import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomerDetailsperorder from '../OrderIdDetails/CustomerDetailsperorder';
import ItemsOrderedPerOder from '../OrderIdDetails/ItemsOrderedPerOder';
import PlacedOrderDetails from './PlacedOrderDetails';
import CustomAxios from '../../../../API/CustomAxios';
import { useSelector } from 'react-redux';


function CustomerPastOrderDetails() {
    const { orderID } = useParams();
    const API_BASE_SMARTSCAP = process.env.REACT_APP_API_SMARTSCAP;
    const [orderDetails, setOrderDetails] = useState([]);
    const [billingAddress, setBillingAdress] = useState();
    const [userId, setUserID] = useState(useSelector((state)=>state.auth.userID));


    useEffect(() => {
        getOrderDetailsByID();
    }, []);

    
    const getOrderDetailsByID = async () => {
        try {
            const response = await CustomAxios({
                method: "GET",
                baseURL: API_BASE_SMARTSCAP,
                url: "getOrdersBySupplierByOrderID",
                params: {
                    orderId: orderID,
                    supplierId: userId,
                },
                headers: { 
                    "Content-Type": "application/json"
                },
            });
            if (response.status === 200) {
                setOrderDetails(response.data.orders[0]);
                console.log("Fetched order details:", response.data.orders[0]);
            }
        } catch (error) {
            console.error("Error fetching order details:", error);
        }
    };

    return (
        <div className="flex flex-col mt-2 gap-4 h-auto overflow-y-auto">
            <h1 className="text-2xl font-semibold text-gray-500 lg:ml-0">
                Order ID: <span className="text-agro_darkgreen font-bold text-md ml-2">{orderDetails.OrderID}</span>
            </h1>


            {/* Order Details */}
            <div className="w-full rounded-md p-4 flex flex-col gap-6 bg-white shadow-md">
                <h1>Order Id: {orderDetails.OrderID}</h1>
                <div className="flex flex-row text-[10px] text-black/50 font-semibold gap-8">
                    <div className="flex flex-row px-2 py-2 gap-3 bg-agro_lightgreen/70 rounded-lg">
                        <h3>Placed On:</h3>
                        <span>{orderDetails.OrderStartDate}</span>
                    </div>
                    <div className="flex flex-row px-2 py-2 gap-3 bg-agro_lightgreen/70 rounded-lg">
                        <h3>Ends On:</h3>
                        <span>{orderDetails.OrderEndDate}</span>
                    </div>
                </div>
            </div>


            {/* Additional Details */}
            <div className="grid lg:grid-cols-3 gap-2 md:grid-cols-2 mob2:grid-cols-1">
                <CustomerDetailsperorder farmerID={orderDetails.FarmerID} newBillingAdress={setBillingAdress} />
                <PlacedOrderDetails orderAmount={orderDetails.totalPrice} status={orderDetails.OrderItems && orderDetails.OrderItems[0].orderStatus} />

                {/* Billing Address */}
                <div className="bg-white shadow-md rounded-md p-4 flex flex-col gap-4 lg:col-span-1 md:col-span-2">
                    <h1>BILLING ADDRESS</h1>
                    <p className="text-black/50 text-[12px]">{billingAddress}</p>
                </div>
            </div>

            {/* {orderDetails.OrderItems && console.log("Order item details:", orderDetails.OrderItems[0])} */}

            {/* Check if orderDetails.OrderItems is defined before logging */}
            {orderDetails.OrderItems && 
             <ItemsOrderedPerOder orderItems={orderDetails.OrderItems[0]} orderID={orderID} onStatusChange={getOrderDetailsByID} /> 
            }

            
        </div>
    );
}

export default CustomerPastOrderDetails;
