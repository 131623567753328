import React, { useState } from 'react';
import BottleImage from '../../Assests/FarmerDashboard/bottleImage.jpg';
import edit from '../../Assests/edit.png';
import deleteImg from '../../Assests/delete.png';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';


function ProductMainCard() {
  const [userRole, setUserRole] = useState("supplier");
  const navigate = useNavigate();
  const initialProductData = {
    productName: 'High-Quality Fertilizer',
    productType: 'fertilizer',
    productDescription:
      ' This high-quality fertilizer is specially formulated to promote strong, healthy plant growth. Packed with essential nutrients like nitrogen, phosphorus, and potassium, it supports robust root development, vibrant foliage, and abundant blooms. Ideal for use in gardens, lawns, and containers, it enhances soil health and improves plant vitality for a flourishing landscape.',
    image: BottleImage,
  };

  const [showEditModel, setShowEditModel] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [imageUrl, setImageUrl] = useState(initialProductData.image);
  const [productData, setProductData] = useState(initialProductData);


  const handleShowEditModel = () => {
    setShowEditModel(true);
  };

  const handleEditProduct = (values) => {
    // Update the product data with the new values
    setProductData({
      productName: values.product_name,
      productType: values.type,
      productDescription: values.product_des,
      image: imageUrl,
    });
    setShowEditModel(false); // Close the modal
  };

  const handleShowDeleteModel = () => {
    setShowDeleteModel(true);
  };

  return (
    <div className='flex flex-col rounded-md bg-white shadow-lg py-10'>
      <div className='rounded-md h-3/6 p-4'>
        <img src={productData.image} alt='Product' />
      </div>
      <div className='h-1/6 p-4'>
        <h2 className='font-bold text-lg text-agro_green'>{productData.productName}</h2>
      </div>
      <div className='h-2/6 px-4 pt-6 pb-10'>
        <p className='text-[12px] text-justify'>{productData.productDescription}</p>
      </div>

      {/* The update and delete icon */}
      <div className={`flex flex-row justify-end px-4 gap-4 ${userRole === "supplier" ? "" :"hidden"}`}>
        <button onClick={handleShowEditModel}>
          <img src={edit} className='w-5 h-5' alt='Edit' />
        </button>
        <button onClick={handleShowDeleteModel}>
          <img src={deleteImg} className='w-5 h-5' alt='Delete' />
        </button>
      </div>



{/* show the Edit Product Modal */}
{showEditModel && (
  <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
    <div className='bg-white p-6 sm:rounded-lg shadow-lg w-[550px] flex flex-col gap-4 relative no-scrollbar overflow-y-auto'>
      <h1 className='text-center text-agro_green font-semibold text-lg'>Update Product</h1>
        <Formik
          initialValues={{
            product_name: productData.productName,
            type: productData.productType,
            image: '',
            product_des: productData.productDescription,
          }}
          onSubmit={handleEditProduct}
        >
        
    {(formik) => (
      <Form className='flex flex-col gap-3'>

      {/* Field for Product Name */}
        <div className='flex flex-row items-center gap-3 h-16 w-full'>
            <label className='text-[13px] text-black/70 w-32'>Product Name</label>
              <div className='flex flex-col w-full'>
                  <Field
                    type='text'
                    id='product_name'
                    name='product_name'
                    className='border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg'
                  />
                  <ErrorMessage
                    name='product_name'
                    component='div'
                    className='text-red-500 text-[9px] font-medium italic mt-1'
                  />
              </div>
          </div>

      {/* Field for Product Type */}
          <div className='flex flex-row items-center gap-3 w-full h-16'>
            <label className='text-[13px] text-black/70 w-32'>Product Type</label>
               <div className='flex flex-col w-full'>
                  <Field
                    as='select'
                    id='type'
                    name='type'
                    className='border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg'
                   >
                    
                    <option value=''>Select Type</option>
                    <option value='fertilizer'>Fertilizer</option>
                    <option value='pesticide'>Pesticide</option>
                    <option value='seeds'>Seeds</option>
                  </Field>
                      
                  <ErrorMessage
                    name='type'
                    component='div'
                    className='text-red-500 text-[9px] font-medium italic mt-1'
                  />
              </div>
          </div>

        {/* Field for Image */}
          <div className='flex flex-row items-center gap-3 w-full h-16'>
            <label className='text-[13px] text-black/70 w-32'>Image</label>
              <div className='flex flex-col w-full'>
                <input
                  type='file'
                  id='image'
                  name='image'
                  onChange={(event) => {
                    formik.setFieldValue('image', event.currentTarget.files[0]);
                      const reader = new FileReader();
                          reader.onload = () => {
                            setImageUrl(reader.result);
                          };
                          reader.readAsDataURL(event.currentTarget.files[0]);
                      }}
                        className='border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg'
                />
                <ErrorMessage
                  name='image'
                  component='div'
                  className='text-red-500 text-[9px] font-medium italic mt-1'
                />
              </div>
          </div>

          {/* Image Preview */}
        <div className='flex flex-row items-center gap-3 w-full'>
          <label className='text-[13px] text-black/70 w-32'></label>
            <div className='flex flex-col w-full'>
                {imageUrl && (
                  <img
                     src={imageUrl}
                     alt='Preview'
                     className='mt-2 w-auto object-cover rounded-lg'
                  />
                )}
            </div>
          </div>


          {/* Field for Product Description */}
          <div className='flex flex-row items-center gap-3 w-full h-16'>
              <label className='text-[13px] text-black/70 w-32'>Description</label>
                 <div className='flex flex-col w-full'>
                      <Field
                        as='textarea'
                        id='product_des'
                        name='product_des'
                        className='border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg'
                      />
                      <ErrorMessage
                        name='product_des'
                        component='div'
                        className='text-red-500 text-[9px] font-medium italic mt-1'
                      />
                 </div>
           </div>

          <button type='submit' className='mt-4 bg-agro_green text-white p-2 rounded-lg'>
              Update
          </button>

          <div className='absolute bg-white right-0 top-0 w-8 h-8 rounded-full'>
              <button
                className='w-full h-full text-red-500 font-bold'
                onClick={() => setShowEditModel(false)}
                >
                  X
              </button>
          </div>
      </Form>
     )}
    </Formik>
  </div>
  </div>
)}

      {/* Delete Confirmation Modal */}
      {showDeleteModel && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
          <div className='bg-white p-6 rounded-lg shadow-lg w-96 flex flex-col items-center justify-center gap-4'>
            <h1 className='text-sm text-center'>
              Are you sure you want to delete{' '}
              <span className='font-semibold text-md'>{productData.productName}</span>?
            </h1>
            <div className='flex flex-row justify-between gap-12 text-white text-sm font-semibold'>
              <button className='bg-agro_green py-2 rounded-md px-4' onClick={() => {navigate("/dashboard/products")}}>
                Delete
              </button>
              <button
                className='bg-red-500 py-2 rounded-md px-4'
                onClick={() => setShowDeleteModel(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductMainCard;
