import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { incrementQuantity, decrementQuantity, removeFromCart } from "../ReduxManagement/cartSlice";
import { FaTrash } from "react-icons/fa";

const CartTable = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const farmerID = useSelector((state) => state.auth.userID); 

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [orderSuccess, setOrderSuccess] = useState(false);

  const handleIncrement = (productId) => {
    dispatch(incrementQuantity(productId));
  };

  const handleDecrement = (productId) => {
    dispatch(decrementQuantity(productId));
  };

  const handleRemove = (productId) => {
    dispatch(removeFromCart(productId));
  };

  const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);
  const totalPrice = cartItems.reduce((total, item) => total + parseFloat(item.price) * item.quantity, 0).toFixed(2);

  // Prepare the order data
  const prepareOrderData = () => {
    return {
      farmerID: farmerID,
      orderStartDate: new Date().toISOString().split("T")[0], // today date
      orderEndDate: new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().split("T")[0], // 7 days from today
      totalPrice: parseFloat(totalPrice),
      orderItems: cartItems.map((item) => ({
        productName: item.brand,
        supplierID: item.supplierID,
        supplierLocation: item.supplierLocation,
        orderStatus: "Pending",
        supplierTotalPrice: item.price * item.quantity,
        itemDetails: [
          {
            itemType: item.type,
            quantity: item.quantity,
            unitPrice: item.price,
            ItemStatus: "Pending"
          }
        ]
      }))
    };
  };

  // Place order function
  const placeOrder = async () => {
    const orderData = prepareOrderData();
    setIsLoading(true);
    setError(null);
    setOrderSuccess(false);

    try {
      const response = await fetch("https://scapione.bethel.network/createOrder", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderData),
      });
      console.log(orderData);
      const data = await response.json();
      if (response.ok) {
        setOrderSuccess(true);
      } else {
        setError(data.message || "An error occurred while placing the order.");
      }
    } catch (err) {
      setError("Failed to place order. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-6">
      <table className="min-w-full bg-white border-collapse shadow-lg">
        <thead className="bg-light_green">
          <tr>
            <th className="py-3 px-4 text-left">Product Name</th>
            <th className="py-3 px-4 text-left">Product Type</th>
            <th className="py-3 px-4 text-left">Volume</th>
            <th className="py-3 px-4 text-left">Price</th>
            <th className="py-3 px-4 text-left">Quantity</th>
            <th className="py-3 px-4 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {cartItems.map((item) => (
            <tr key={item.id}>
              <td className="py-2 px-4">{item.brand}</td>
              <td className="py-2 px-4">{item.type}</td>
              <td className="py-2 px-4">{item.volume}</td>
              <td className="py-2 px-4">${item.price}</td>
              <td className="py-2 px-4">
                <button
                  onClick={() => handleDecrement(item.id)}
                  className="px-2 py-1 bg-gray-300 text-white rounded"
                >
                  -
                </button>
                <span className="mx-2">{item.quantity}</span>
                <button
                  onClick={() => handleIncrement(item.id)}
                  className="px-2 py-1 bg-gray-400 text-white rounded"
                >
                  +
                </button>
              </td>
              <td className="py-2 px-4">
                <button
                  onClick={() => handleRemove(item.id)}
                  className="text-red-600 hover:text-red-800"
                >
                  <FaTrash className="text-xl" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="mt-4 flex justify-between items-center p-2 bg-light_green/50 rounded-lg">
        <div className="text-xl">Total Items: {totalItems}</div>
        <div className="text-xl">Total: ${totalPrice}</div>
      </div>

      {/* Place Order Button */}
      <div className="mt-4 flex justify-end flex-col items-end">
        <button
          onClick={placeOrder}
          className={`w-1/4 py-2 px-4 text-white rounded-lg ${
            isLoading ? "bg-green-400" : "bg-green-600 hover:bg-green-700"
          }`}
          disabled={isLoading || cartItems.length === 0}
        >
          {isLoading ? "Placing Order..." : "Place Order"}
        </button>

        {/* Show success or error message */}
        {orderSuccess && (
          <div className="mt-4 text-green-600">Order placed successfully!</div>
        )}
        {error && <div className="mt-4 text-red-600">{error}</div>}
      </div>
    </div>
  );
};

export default CartTable;
