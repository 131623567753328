import { combineReducers, configureStore } from '@reduxjs/toolkit';
import signupReducer from './signupSlice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './authSlice';
import cartReducer from './cartSlice'
import headerContentReducer from './headercontentSlice';

const rootReducer = combineReducers({
  signup:signupReducer,
  auth:authReducer,
  cart:cartReducer,
  headercontent:headerContentReducer,
})

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
